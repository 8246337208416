import React from 'react'
import styled, { css } from 'styled-components'
import { formatNumberWithCommas } from '../../../utils'
import { round } from '../../../utils/lodash.utils'

export const exhibitPdfTotalsStyles = css``

const LeaseTaxReimbrsPdfTotalsView = styled.div`
	${exhibitPdfTotalsStyles}
`

type LeaseTaxReimbrsPdfTotalsProps = {
	// config: LeaseTaxReimbrsPdfConfig
	totalTaxes: number
}
const LeaseTaxReimbrsPdfTotals: React.FC<LeaseTaxReimbrsPdfTotalsProps> = ({ totalTaxes }) => {
	return (
		<LeaseTaxReimbrsPdfTotalsView className='totals'>
			<div className='label'>Total:</div>
			<div className='val'>{formatNumberWithCommas(round(totalTaxes, 2))}</div>
		</LeaseTaxReimbrsPdfTotalsView>
	)
}
export default LeaseTaxReimbrsPdfTotals
