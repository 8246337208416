import { zonedTimeToUtc } from 'date-fns-tz'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
// import parseISO from 'date-fns/parseISO'
import dateFNSFormat from 'date-fns/format'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import replace from 'lodash/replace'
import round from 'lodash/round'
import startCase from 'lodash/startCase'
import sumBy from 'lodash/sumBy'
import toNumber from 'lodash/toNumber'
import trim from 'lodash/trim'
import words from 'lodash/words'
import React from 'react'
import { UserRole } from '../gql_generated/document_types'
import { Address, Contact, EstateActivity, Org, Parcel, User } from '../gql_generated/graphql'
import { Err, EstateTypeEnum } from '../Types'
import { UrqlLoggerLevel } from '../Urql.config/Urq.config.log'

export const isDev = () => process.env.REACT_APP_ENV === 'dev'

export const parseQueryParams = (search: string | { search?: string }): { [x: string]: string } => {
	const s = isString(search) ? search : search?.search || '' // might be the location object

	const queryString = replace(s, '?', '')

	if (queryString.length)
		return queryString.split('&').reduce((obj: any, curr) => {
			const [query, param] = curr.split('=')

			obj[query] = param
			return obj
		}, {})

	return {}
}

export const isExternalLink = (link?: string) => (link ? link.substring(0, 4) === 'http' : false)

export const parseBytes = (bytes?: number, decimals = 2) => {
	if (bytes === 0 || !isNumber(bytes)) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const asAcres = (num: number) => {
	let number = toNumber(num)
	if (!number) return '0 acres'

	number = round(number, 2)

	let formattedVal = `${number} acres`

	if (typeof Intl === 'object') formattedVal = `${Intl.NumberFormat().format(number)} acres`

	return formattedVal
}

export const isDate = (date: any) => date instanceof Date && !isNaN(date as any)

export const formatDate = (date?: string | Date | null, format?: string | 'input') => {
	if (!date) return ''
	if (!isString(format)) format = 'MM/dd/yyyy'

	// console.log('formatDate beginning with date: ', date, 'and format: ', format)
	let dateForFormat = date

	const utcMil = toNumber(date)

	if (!isNaN(utcMil)) {
		// console.log('formateDate - converting to true date type from: ', date)
		try {
			dateForFormat = new Date(utcMil)
		} catch (err) {
			console.error('Unable to create date from utc mill : ', utcMil)
			return ''
		}
	} else if (typeof date === 'string') {
		try {
			//dateForFormat = new Date(date)
			dateForFormat = parseISO(date)
		} catch (err) {
			// parseISO didn't work.  Try parsing it the way the date inputs format it:
			try {
				dateForFormat = parse(date, 'MM/dd/yyyy', new Date())
			} catch (err) {
				console.error('Unable to create date from date : ', date)
				return ''
			}
		}
		// console.log('Converted string date: ', date, ' to object Date: ', dateForFormat)
	}

	if (format === 'input') format = 'yyyy-MM-dd'

	if (typeof dateForFormat === 'string' || !isDate(dateForFormat)) {
		console.log('formatDate | Unable to parse date that was passed in: ', date)
		return ''
	}

	// console.log('formatDate about to format: ', dateForFormat, 'with format: ', format)

	const dateStr = dateFNSFormat(dateForFormat, format)

	return dateStr
}

export const estateTypes = [
	{ type: 'srf', typeFull: 'surface' },
	{ type: 'ind', typeFull: 'industrial mineral' },
	{ type: 'min', typeFull: 'mineral' },
	{ type: 'oil', typeFull: 'oil & gas' },
	{ type: 'geo', typeFull: 'geothermal' },
]

export const formatNumberWithCommas = (num?: number) => {
	return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
}

export const formatCurrency = (amount?: number | string | null) => {
	// console.log('beginning with amount: ', amount)
	const number = toNumber(amount)
	if (!number) return '$0.00'
	let formattedVal = `$${number.toFixed(2)}`

	if (typeof Intl === 'object')
		formattedVal = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		}).format(number)

	return formattedVal
}

export const priceToNumber = (amount?: string | null): number =>
	amount ? toNumber(trim(replace(amount, ',', ''))) : 0

// TODO: add coloring??
export const formatStatus = (status?: string | null): string => {
	let formatted = status || ''
	if (status === 'false') formatted = 'None'
	if (status === 'surface_conveyed') formatted = 'Conveyed'

	return startCase(formatted)
}

export const iconFromStatus = (status?: string | null): string | null =>
	status === 'closed' || status === 'surface_conveyed' ? 'srf' : null

export const parseStatus = (status?: string | null): { txt: string; icon: string | null } => {
	return {
		txt: formatStatus(status),
		icon: iconFromStatus(status),
	}
}

export const parseErrMsg = (error?: (Error & { msg?: string }) | null): string | null => {
	if (!error) return null
	let errMsg = error?.msg || error?.message
	const networkErrs = get(error, 'networkError.result.errors')

	if (networkErrs?.length) errMsg = networkErrs[0]?.message

	return errMsg
}

export const parseErrCode = (error?: Error | null): string | null => {
	if (!error) return '0000'

	console.log('Error: ', { ...error })

	let errCode = get(error, 'code', get(error, 'exception.code', '0000'))

	return errCode
}

export const parseApolloErrs = (error?: Error | null): Err[] => {
	if (!error) return []

	const gqlErrs = get(error, 'graphQLErrors', error)
	const errMsg = get(error, 'message')
	const networkErr = get(error, 'networkError')

	return gqlErrs?.length
		? gqlErrs.map(({ ecloErr, message, code }: any) => {
				return (
					ecloErr || {
						origMsg: message,
						code,
					}
				)
		  })
		: [
				networkErr || {
					humanMsg: errMsg,
				},
		  ]
}

export const contactNameTitle = (contact?: Contact | null) => {
	const { fullName, firstName, lastName, title } = contact || {}
	const name = fullName || `${firstName || ''}${lastName ? ` ${lastName}` : ''}`
	// return `${name}${title ? ` | ${title}` : ''}`

	return (
		<pre className='name-title'>
			<span className='name'>{name}</span>
			{title ? (
				<>
					{' '}
					| <em className='title'>{title}</em>
				</>
			) : null}
		</pre>
	)
}

export const getPrimaryContact = ({
	org,
	user = null,
}: {
	org?: Pick<Org, 'contacts'> | null
	user?: User | null
}): Contact | null => {
	const contacts = org?.contacts
	if (!contacts || !contacts.length) return null

	const primaryContact = contacts.find(({ primary }) =>
		user ? !!primary?.includes(user.id) : !!primary
	)

	return primaryContact || contacts[0]
}

export const getTotalAcresFromActivity = (
	activity?: EstateActivity[],
	opts?: {
		estateType?: string
		calcConveyed?: boolean
		includeAssigned?: boolean
		includeTerminate?: boolean
		leaseId?: number
		agreementId?: number
		easementId?: number
		saleId?: number
		acquisitionId?: number
	}
) => {
	const {
		estateType = 'srf',
		calcConveyed,
		leaseId,
		agreementId,
		easementId,
		saleId,
		acquisitionId,
		includeAssigned,
		includeTerminate,
	} = opts || {}
	if (!activity?.length) return 0

	// console.log('calculating from activity: ', activity)
	// console.log('options: ', opts)

	const estateActivity = activity.filter(a => {
		if (!calcConveyed && a?.estate?.status === 'Conveyed') return false

		if (leaseId && a.leaseId !== leaseId) return false
		else if (agreementId && a.agreementId !== agreementId) return false
		else if (easementId && a.easementId !== easementId) return false
		else if (saleId && a.saleId !== saleId) return false
		else if (acquisitionId && a.acquisitionId !== acquisitionId) return false

		if (estateType && a.estateType !== estateType) return false

		return true
	})

	// console.log('calculating activity acres: ', estateActivity)

	return sumBy(estateActivity, ea => {
		let shouldSum = true

		if (leaseId || agreementId) {
			if (ea.assigneeId && !includeAssigned) shouldSum = false
			else if (ea.isTerminated && !includeTerminate) shouldSum = false
		}
		const sum = shouldSum ? ea.acres || ea.estate?.acres || 0 : 0

		return sum
	})
}

export const getAliquotFromRelatedResource = (opts: {
	activity?: EstateActivity[]
	parcel?: Parcel
	estateType?: string
	leaseId?: number
	agreementId?: number
	easementId?: number
	saleId?: number
	acquisitionId?: number
}) => {
	const {
		estateType = 'srf',
		leaseId,
		agreementId,
		easementId,
		saleId,
		acquisitionId,
		activity,
		parcel,
	} = opts

	const estate = estateType && parcel ? parcel[estateType as EstateTypeEnum] : null

	const estateActivity = activity || parcel?.activity || estate?.activity

	const ea = estateActivity?.find(a =>
		((leaseId && a.leaseId === leaseId) ||
			(agreementId && a.agreementId === agreementId) ||
			(easementId && a.easementId === easementId) ||
			(saleId && a.saleId === saleId)) &&
		acquisitionId &&
		a.acquisitionId === acquisitionId &&
		estateType
			? a.estateType === estateType
			: true
	)

	return ea?.aliquot || ea?.estate?.aliquot || estate?.aliquot || ''
}

export const findActivityInParcelById = ({
	parcel,
	parentId,
	parentType,
}: {
	parcel: Parcel
	parentId: number
	parentType: string
}): EstateActivity | undefined =>
	parcel.activity?.find(activ =>
		includes(parentType, 'sale')
			? activ.saleId === parentId
			: includes(parentType, 'acquisition')
			? activ.acquisitionId === parentId
			: includes(parentType, 'lease')
			? activ.leaseId === parentId
			: includes(parentType, 'easement')
			? activ.easementId === parentId
			: includes(parentType, 'agreement')
			? activ.agreementId === parentId
			: false
	)

export const hasAccess = (role: string, allowed: string[]): boolean => includes(allowed, role)

export const isPastDate = (date?: string | null) => {
	if (!date) return false
	const today = (new Date() as unknown as number) * 1
	let pastDate = toNumber(date)
	if (isNaN(pastDate)) {
		pastDate = (new Date(date) as unknown as number) * 1
	}

	return pastDate ? today >= pastDate : false
}

export const parseDateForServer = (date?: string | null) =>
	date?.length ? zonedTimeToUtc(date, 'America/Los_Angeles').toISOString() : null

export const parseFullname = (fullName: string, opts?: { asObj?: boolean }) => {
	const { asObj } = opts || {}
	const arry = words(fullName)
	const obj: any = {}
	if (asObj) {
		if (arry.length > 1) obj.lastName = arry[arry.length - 1]
		obj.firstName = arry.slice(0, arry.length - 1).join(' ')
	}
	return asObj ? obj : arry
}

export const addressString = (address?: Address | null) => {
	const { street, street2, city, zip, state } = address || {}

	const country = address?.country === 'United States' ? 'USA' : address?.country

	const addressArry = [street, street2, city, state, country, zip].filter(item => !!item)

	return addressArry.join(', ')
}

export const useQueryDebug = (level: UrqlLoggerLevel) => {
	return React.useMemo(() => ({ debug: level }), [level])
}

export const nnrMgrs = [UserRole.Landman]

export const nnrTeam = [
	UserRole.Landman,
	UserRole.Accountant,
	UserRole.Officer,
	UserRole.Shareholder,
]
