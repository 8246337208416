import React from 'react'
import styled, { css } from 'styled-components/macro'
import { leaseColsForParcelLeases } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Lease.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LeaseFilter, LeaseParent, LeaseStatus } from '../../../gql_generated/document_types'
import { usePaginatedLeasesQuery } from '../../../gql_generated/graphql'
import { RelatedParcelLeaseModal } from '../RelatedModals/Parcel.related.lease.modal'

export const relatedParcelLeasesStyles = css``

const RelatedParcelLeasesView = styled.div`
	${relatedParcelLeasesStyles}
`

export type RelatedParcelLeasesProps = {
	parcelId: number
}

export const RelatedParcelLeases: React.FC<RelatedParcelLeasesProps> = ({ parcelId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLeasesQuery({
		variables: {
			params: {
				...paginationStore.params,
				filterOptions: [{ filter: LeaseFilter.Status, vals: [LeaseStatus.Active] }],
				parentOptions: [
					{
						parentType: LeaseParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const leases = data?.page?.leases
	return (
		<RelatedParcelLeasesView>
			<ResourcesTable
				dataset={leases}
				colsConfig={leaseColsForParcelLeases(parcelId)}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={lease => <RelatedParcelLeaseModal leaseId={lease.id} />}
				resourceType='lease'
			/>
		</RelatedParcelLeasesView>
	)
}
