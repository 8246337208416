import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
	EstateActivity,
	RoyaltyActivity,
	useEstateActivityByParentQuery,
} from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { ArrayField, Block, Btn, P, Spinner } from '../../../UI'
import { RoyaltyAddEditActivityParcelCard } from './Ops.Royalty.add.details.parcel.card'

const AddEditRoyaltyOnResourceActivityDetailsView = styled.div`
	.array-field {
		border: none;
		padding: 0;
	}
	.activity-details {
		min-height: 100px;
	}
	.activity-detail {
		padding-top: 1em;
		margin-bottom: 1em;
		border-left: 3px solid ${props => props.theme.colors.orange.val};
		.accordion {
			padding-top: 1em;
		}
		.parcel-identifiers,
		.info {
			display: flex;
			align-items: center;
			span {
				margin-right: 1em;
			}
		}
		.info {
			.icon {
				margin-right: 1em;
			}
		}
	}
`

const getActivityMatch = (
	parcelId: number,
	parentType: string,
	estateType: string,
	activity?: EstateActivity[]
): EstateActivity =>
	activity?.find(
		a => a.parcelId === parcelId && a.activityType === parentType && a.estateType === estateType
	) || ({} as EstateActivity)

type AddEditRoyaltyOnResourceActivityDetailsProps = {
	previousStep?: () => void
	parcelIds: number[]
	parentId: number
	estateType: string
	parentType: string
}
const AddEditRoyaltyOnResourceActivityDetails: React.FC<AddEditRoyaltyOnResourceActivityDetailsProps> = ({
	previousStep,
	parcelIds,
	parentId,
	estateType,
	parentType,
}) => {
	const [{ data, fetching }] = useEstateActivityByParentQuery({
		variables: { parentId, parentType },
	})

	const { activity } = data || {}

	const { values, setValues } = useFormikContext<{
		royaltyActivity: RoyaltyActivity[]
	}>()

	useEffect(() => {
		if (activity?.length && !values.royaltyActivity.filter(({ id }) => !!id).length) {
			setValues((vals: any) => {
				return {
					...vals,
					royaltyActivity: parcelIds.map(parcelId => {
						const { id } = getActivityMatch(
							parcelId,
							parentType,
							estateType,
							activity as EstateActivity[]
						)

						return {
							estateActivityId: id,
							acres: 0,
							aliquot: '',
						}
					}),
				}
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parcelIds, activity, estateType, parentType])

	return (
		<AddEditRoyaltyOnResourceActivityDetailsView>
			<div className='ctrl-bar'>
				<Btn className='cta' onClick={() => previousStep && previousStep()}>
					Previous
				</Btn>
			</div>
			<Block>
				<P>Configure details for royalty on each {estateType}. estate below</P>
				<P>
					NOTE: Creating custom aliquot/acreage will override default aliquot/acreage (either on the
					sale to estate connection or parcel estate).
				</P>
			</Block>
			<div className='activity-details'>
				{fetching || !values.royaltyActivity?.length ? (
					<Spinner />
				) : (
					<ArrayField
						name='royaltyActivity'
						render={({ replace }) =>
							parcelIds.map((parcelId, idx) => {
								const ea = getActivityMatch(
									parcelId,
									parentType,
									estateType,
									activity as EstateActivity[]
								)

								return (
									<RoyaltyAddEditActivityParcelCard
										key={parcelId}
										idx={idx}
										parcelId={parcelId}
										replaceHandler={replace}
										estateActivity={ea}
										estateType={estateType as EstateTypeEnum}
									/>
								)
							})
						}
					/>
				)}
			</div>
		</AddEditRoyaltyOnResourceActivityDetailsView>
	)
}
export default AddEditRoyaltyOnResourceActivityDetails
