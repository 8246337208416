import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EditEstateActivity } from '../../../Components/common/Activity/Activity.edit'
import { EstateActivityPreview } from '../../../Components/common/Activity/Activity.preview'
import Protect from '../../../Components/common/Protect'
import { AddParcelsToResourceBtn } from '../../../Components/Ops/Ops.Parcels.addToResource'
import { LeftBar, Modal, useModalCtrl } from '../../../Components/UI'
import { AddParcelEstatesToSaleOp } from '../../../Components/UI/Table.resources/Table.global.ops/AddParcelEstateTypesToSale.op'
import { RemoveParcelEstatesFromSaleAcquisitionOp } from '../../../Components/UI/Table.resources/Table.global.ops/RemoveParcelEstateTypesFromSaleAcquisition.op'
import { RemoveRelatedParcelsOp } from '../../../Components/UI/Table.resources/Table.global.ops/RemoveParcelsFromRelated.op'
import { saleAcqusitionRelatedParcelsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import {
	ResourcesTable,
	ResourceTableGlobalOpConfig,
} from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelParent, SaleStatus } from '../../../gql_generated/document_types'
import { usePaginatedParcelsQuery, useSaleForStatusQuery } from '../../../gql_generated/graphql'
import { PrimaryResourceType } from '../../../Types'
import { nnrMgrs } from '../../../utils'
import { RelatedSaleParcelModal } from '../Related.Modals/Sale.related.parcel.modal'

export const relatedSaleParcelStyles = css``

const RelatedSaleParcelsView = styled.div`
	${relatedSaleParcelStyles}
`

export type RelatedSaleParcelsProps = {
	saleId: number
}
export const RelatedSaleParcels: React.FC<RelatedSaleParcelsProps> = ({ saleId }) => {
	const [statusResults] = useSaleForStatusQuery({ variables: { id: saleId } })

	const status = statusResults.data?.sale?.status
	const canEdit = !!status && status !== SaleStatus.Closed

	const paginationStore = usePaginationParams()

	const [results] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Sale,
						parentIds: [saleId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.parcels

	const parentProps = {
		parentId: saleId,
		parentType: 'sale' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			txt: 'Remove',
			op: props => <RemoveRelatedParcelsOp {...props} {...parentProps} />,
		},
		{
			txt: 'Add Estate Types',
			op: props => <AddParcelEstatesToSaleOp {...props} {...parentProps} />,
		},
		{
			txt: 'Remove Estate Types',
			op: props => <RemoveParcelEstatesFromSaleAcquisitionOp {...props} {...parentProps} />,
		},
	]

	const [[showEdit, openEdit, closeEdit, editParcelId]] = useModalCtrl<number>()
	const [[showPreview, openPreview, closePreview, previewParcelId]] = useModalCtrl<number>()

	return (
		<RelatedSaleParcelsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={saleAcqusitionRelatedParcelsColsConfig(saleId)}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedSaleParcelModal parcelId={data.id} />}
				resourceType='parcel'
				tableOps={canEdit ? tableOps : undefined}
				includeSearch
				rowOps={[
					{
						icon: canEdit ? 'edit' : 'eye',
						allowed: [...nnrMgrs],
						op: parcel => (canEdit ? openEdit(parcel.id) : openPreview(parcel.id)),
					},
				]}
			>
				<Protect allowed={nnrMgrs}>
					{canEdit ? (
						<LeftBar>
							<AddParcelsToResourceBtn
								config={{
									parentId: saleId,
									parentType: 'sale',
									showConveyedToggle: true,
								}}
							/>
						</LeftBar>
					) : null}
				</Protect>
			</ResourcesTable>

			<Modal show={showEdit} closeHandler={closeEdit}>
				{editParcelId ? (
					<EditEstateActivity
						parcelId={editParcelId}
						parentId={saleId}
						parentType='sale'
						cb={closeEdit}
					/>
				) : null}
			</Modal>
			<Modal show={showPreview} closeHandler={closePreview}>
				{previewParcelId ? (
					<EstateActivityPreview parcelId={previewParcelId} parentId={saleId} parentType='sale' />
				) : null}
			</Modal>
		</RelatedSaleParcelsView>
	)
}
