import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { LeaseSchedInterval } from '../../../gql_generated/document_types'
import { calcIsSinglePayment } from '../../../utils'
import { startCase } from '../../../utils/lodash.utils'
import { IntegerField, PercentageField, Select, Text, Textarea, ToggleField } from '../../UI'

import * as Yup from 'yup'

export const leaseTermsYupSchema = Yup.object().shape({
	dateFrom: Yup.date().required('Please enter a start date'),
	dateTo: Yup.date().required('Please enter an end date'),
	schedAmount: Yup.string().required('Payment schedule is required'),
	schedInterval: Yup.string().required('Payment schedule is required'),
	termNotes: Yup.string().required('Please add a description of the terms'),
})

const LeaseTermFieldsView = styled.div`
	.flex {
		${props => props.theme.media.sdesk} {
			& > * {
				margin-right: 1em;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.schedule {
		& > .flex-tablet {
			align-items: center;
			grid-gap: 1em;
			.every {
				font-weight: 700;
			}
			.form-field {
				margin: 0;
			}
		}
	}
`
type LeaseTermFieldProps = {
	namePrefix?: string
	showDates?: boolean
	isTermNotesRequired?: boolean
}
const LeaseTermFields: React.FC<LeaseTermFieldProps> = ({
	namePrefix,
	isTermNotesRequired,
	showDates = true,
}) => {
	const { values } = useFormikContext<any>()
	const isSinglePayment = calcIsSinglePayment({ terms: [values] } as any)
	return (
		<LeaseTermFieldsView>
			{showDates ? (
				<section className='dates'>
					<legend>Term Dates</legend>
					<div className='flex'>
						<Text name={`${namePrefix || ''}dateFrom`} type='date' label='From' isReq />
						<Text name={`${namePrefix || ''}dateTo`} type='date' label='To' isReq />
					</div>
				</section>
			) : null}
			<section className='schedule'>
				<legend>Payment Schedule</legend>
				<div className='flex-tablet'>
					{/* <Select
						name='schedType'
						options={[
							{ txt: 'Every', val: 'every' },
							{ txt: 'Annualy', val: 'annualy' },
							{ txt: 'Monthly', val: 'monthly' },
							{ txt: 'Quarterly', val: 'quarterly' },
						]}
					/> */}
					{isSinglePayment ? null : <div className='every'>EVERY</div>}
					{isSinglePayment ? null : <IntegerField name={`${namePrefix || ''}schedAmount`} isReq />}
					<Select
						name={`${namePrefix || ''}schedInterval`}
						options={Object.values(LeaseSchedInterval).map(interval => ({
							txt: interval === LeaseSchedInterval.Single ? 'Single Payment' : startCase(interval),
							val: interval,
						}))}
					/>
				</div>
			</section>
			<section className='rate'>
				<legend>Rate Increase</legend>
				<PercentageField name={`${namePrefix || ''}rate`} />
			</section>
			<section className='options'>
				<legend>Options</legend>

				<div className='flex has-toggles'>
					<ToggleField
						name={`${namePrefix || ''}hasPurchaseOption`}
						label='Has option to purchase'
					/>
					<ToggleField name={`${namePrefix || ''}hasContClause`} label='Continuation Clause' />
					<ToggleField name={`${namePrefix || ''}hasTaxReimbursements`} label='Tax Reimbursable' />
				</div>
			</section>
			<section className='notes'>
				<Textarea
					name={`${namePrefix || ''}termNotes`}
					label='Term Notes/Description'
					isReq={isTermNotesRequired}
				/>
			</section>
		</LeaseTermFieldsView>
	)
}
export default LeaseTermFields
