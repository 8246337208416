import React from 'react'
import TableCellPrint, { TablePrintCell } from './Table.cell.print'

export type TablePrintRow = {
	rangeTitle?: React.ReactNode
	rangeTitleColSpan?: number
	colSpan?: number
	isSpacer?: boolean
	sectionTitle?: React.ReactNode
	sectionFooter?: React.ReactNode
	cells: TablePrintCell[]
}

type TableRowPrintProps = {
	row: TablePrintRow
}
const TableRowPrint: React.FC<TableRowPrintProps> = ({ row }) => {
	const { rangeTitle, cells, rangeTitleColSpan, isSpacer, sectionTitle, colSpan, sectionFooter } =
		row || {}
	return (
		<tr>
			{isSpacer ? (
				<td className='spacer' colSpan={colSpan || cells.length}>
					' '
				</td>
			) : sectionTitle ? (
				<td className='section-title-cell' colSpan={colSpan || cells.length}>
					{sectionTitle}
				</td>
			) : rangeTitle ? (
				<>
					<td colSpan={rangeTitleColSpan || cells.length} className='range-title-cell'>
						{rangeTitle}
					</td>
					{cells.map((cell, idx) => (
						<td className='range-title-cell' key={idx}>
							{cell.val}
						</td>
					))}
				</>
			) : sectionFooter ? (
				<td className='section-footer-cell' colSpan={colSpan || cells.length}>
					{sectionFooter}
				</td>
			) : (
				cells.map((cell, idx) => <TableCellPrint key={idx} cell={cell} />)
			)}
		</tr>
	)
}
export default TableRowPrint
