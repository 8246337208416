import React from 'react'
import styled from 'styled-components'
import { RelatedResourcesCard } from '../../../Components/common/RelatedResource.card'
import { Spinner, Tab, Tabs } from '../../../Components/UI'
import { RelatedAcquisitionDocs } from './Acquisition.related.docs'
import { RelatedAcquisitionLogs } from './Acquisition.related.logs'
import { RelatedAcquisitionOrgs } from './Acquisition.related.orgs'
import { RelatedAcquisitionParcels } from './Acquisition.related.parcels'

const Section = styled.section``

type AcquisitionRelatedResourcesProps = {
	acquisitionId: number
}

const AcquisitionRelatedResources: React.FC<AcquisitionRelatedResourcesProps> = ({
	acquisitionId,
}) => {
	return (
		<Section>
			<RelatedResourcesCard>
				{acquisitionId ? (
					<Tabs renderOnSelect>
						<Tab nav='Parcels' icon='parcel'>
							<RelatedAcquisitionParcels acquisitionId={acquisitionId} />
						</Tab>

						<Tab nav='Orgs' icon='org'>
							<RelatedAcquisitionOrgs acquisitionId={acquisitionId} />
						</Tab>

						<Tab nav='Docs' icon='doc'>
							<RelatedAcquisitionDocs acquisitionId={acquisitionId} />
						</Tab>
						<Tab nav='Activity' icon='log'>
							<RelatedAcquisitionLogs acquisitionId={acquisitionId} />
						</Tab>
					</Tabs>
				) : (
					<Spinner />
				)}
			</RelatedResourcesCard>
		</Section>
	)
}
export default AcquisitionRelatedResources
