import isPlainObject from 'lodash/isPlainObject'
import { Parcel } from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { formatNumberWithCommas, isDev } from '../../../utils'
import { round, sortBy } from '../../../utils/lodash.utils'
import { EM, TablePrintCell, TablePrintRow } from '../../UI'

export const genLeaseTaxReimbrsTwnFromParcel = ({ town, range, meridian }: Parcel) =>
	`Township ${town}, Range ${range}, ${meridian}`

const genCountyTitlePrintRow = (
	data: {
		county: string
		state: string
	}
	// config: LeaseTaxReimbrsPdfConfig
): TablePrintRow => {
	const { county, state } = data

	// const {  } = config

	return {
		cells: [],
		sectionTitle: (
			<EM>
				All that property situated in {county}, {state.toUpperCase()}, more particularly described
				as:
			</EM>
		),
		colSpan: 5,
	}
}

const genTwnTitlePrintRow = (
	data: {
		town: string
		range: string
		meridian: string
	}
	// config: LeaseTaxReimbrsPdfConfig
): TablePrintRow => {
	const { town, range, meridian } = data
	// const {  } = config

	return {
		rangeTitle: `Township ${town}, Range ${range}, ${meridian}`,
		rangeTitleColSpan: 3,
		cells: [
			{
				val: 'APN',
			},
			{
				val: 'Amount',
			},
		],
	}
}

const genSecPrintRow = (
	data: {
		section: string
		aliquot: string
		taxes: number
		apn?: string
	}
	// config: LeaseTaxReimbrsPdfConfig
): TablePrintRow => {
	// const {  } = config

	const { section, aliquot, taxes, apn } = data

	const cells: Array<TablePrintCell | string> = [
		'Section',
		section,
		{ val: aliquot, isMultiLine: true },
		apn || '',
	]

	cells.push(`${formatNumberWithCommas(taxes)}`)

	return {
		cells: cells.map(val =>
			isPlainObject(val) ? (val as unknown as TablePrintCell) : { val }
		) as TablePrintCell[],
	}
}

const genSecPrintRowFromParcel = (
	parcel: Parcel,
	estateType: EstateTypeEnum
	// config: LeaseTaxReimbrsPdfConfig
): TablePrintRow => {
	const { section, apn, estates, currentPropertyTax } = parcel
	const estate = parcel[estateType] || estates.find(({ type }) => type === estateType)
	const aliquot = estate?.aliquot || ''
	const taxes = currentPropertyTax?.amount || 0

	return genSecPrintRow(
		{
			section,
			aliquot,
			taxes,
			apn,
		}
		// config
	)
}

const genCountyTotalPrintRow = (
	data: {
		county: string
		taxes: number
	}
	// config: LeaseTaxReimbrsPdfConfig
) => {
	// const {  } = config
	const { county, taxes } = data

	return {
		cells: [],
		colSpan: 5,
		sectionFooter: (
			<span className='section-footer'>
				<EM>Total taxes for {county} county:</EM>
				<span className='taxes'>{formatNumberWithCommas(round(taxes, 2))}</span>
			</span>
		),
	}
}

type TownObj = {
	town: string
	range: string
	meridian: string
	parcels: Parcel[]
}

const genTownObjsFromParcels = (parcels: Parcel[]) =>
	sortBy(parcels, ({ town, range }) => `${town}${range}`).reduce((array, parcel) => {
		const arry = [...array] as TownObj[]
		const { town, range, meridian } = parcel

		const townIdx = arry.findIndex(
			t => t.town === town && t.range === range && t.meridian === meridian
		)

		if (townIdx >= 0) {
			arry[townIdx].parcels?.push(parcel)
		} else {
			arry.push({
				town,
				range,
				meridian,
				parcels: [parcel],
			})
		}

		return arry
	}, [] as TownObj[])

const genTownPrintRowsFromObj = (
	townObj: TownObj,
	estateType: EstateTypeEnum
	// config: LeaseTaxReimbrsPdfConfig
): TablePrintRow[] => {
	const { town, range, meridian, parcels } = townObj

	const secRows = sortBy(parcels, 'section')?.map(parcel =>
		genSecPrintRowFromParcel(parcel, estateType)
	)

	return [genTwnTitlePrintRow({ town, range, meridian }), ...secRows]
}

type CountyObj = {
	state: string
	county: string
	parcels: Parcel[]
	taxes: number
}

const genCountyPrintRowFromObj = (
	countyObj: CountyObj,
	estateType: EstateTypeEnum
	// config: LeaseTaxReimbrsPdfConfig
): TablePrintRow[] => {
	const { parcels, county, state, taxes } = countyObj

	const townObjs = genTownObjsFromParcels(parcels)

	const townRows = townObjs.reduce(
		(arry, townObj) => [...arry, ...genTownPrintRowsFromObj(townObj, estateType)],
		[] as TablePrintRow[]
	)

	return [
		genCountyTitlePrintRow({ state, county }),
		...townRows,
		genCountyTotalPrintRow({ county, taxes }),
	]
}

export type LeaseTaxReimbrsPdfPrintRowsAndTaxes = {
	printRows: TablePrintRow[]
	taxes: number
}

export const genLeaseTaxReimbrsPdfPrintRowsFromParcels = (
	parcels: Parcel[],
	estateType: EstateTypeEnum
	// config: LeaseTaxReimbrsPdfConfig
): LeaseTaxReimbrsPdfPrintRowsAndTaxes => {
	isDev() && console.log('Generating print rows from parcels')
	const counties = sortBy(parcels, 'county').reduce((array, parcel) => {
		const { county, state, currentPropertyTax } = parcel
		const arry = [...array] as CountyObj[]

		const taxes = currentPropertyTax?.amount || 0

		const countyIdx = arry.findIndex(c => c.county === county)
		if (countyIdx >= 0) {
			arry[countyIdx].parcels?.push(parcel)
			arry[countyIdx].taxes += taxes
		} else {
			arry.push({
				state,
				county,
				parcels: [parcel],
				taxes,
			})
		}

		return arry
	}, [] as CountyObj[])

	let taxes = 0
	const printRows = counties.reduce((arry, c) => {
		taxes += c.taxes
		return [...arry, ...genCountyPrintRowFromObj(c, estateType)]
	}, [] as TablePrintRow[])

	return {
		printRows,
		taxes,
	}
}
