import { RouteComponentProps } from '@reach/router'
import styled, { css } from 'styled-components'
import CardsSection from '../../Components/common/CardsSection'
import { PropertyTaxesPDF } from '../../Components/common/PropertyTaxes.pdf'
import Page from '../../Components/common/Resources/Resource.page'
import {
	Btn,
	Card,
	Icon,
	Modal,
	PrintWindow,
	SelectCountyRaw,
	Spinner,
	useModalCtrl,
} from '../../Components/UI'
import { useParcelsForPropertyTaxReportQuery } from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'

export const propertyTaxReportPageStyles = css`
	.op-bar {
		display: flex;

		.create-report-btn {
			margin-left: 1em;
			display: flex;
			padding: 0.5em 1em;
			border: solid 1px;
			color: ${props => props.theme.colors.btn.val};

			&:disabled {
				color: ${props => props.theme.colors.disabled.val};
			}
		}
	}
`

const PropertyTaxesReportPageView = styled.div`
	${propertyTaxReportPageStyles}
`

export const PropertyTaxesReportPage = (_: RouteComponentProps): JSX.Element => {
	const [county, setCounty] = useState('')

	usePageData({ pageTitle: 'Property Tax Report', icon: 'receipt' })

	const [[showModal, openModal, closeModal]] = useModalCtrl()

	const [{ data }] = useParcelsForPropertyTaxReportQuery({ variables: { county }, pause: !county })
	const canRender = !!data?.parcels

	const year = new Date().getFullYear()

	return (
		<PropertyTaxesReportPageView>
			<Page isLoaded>
				<CardsSection>
					<Card titleText='Select County'>
						<div className='op-bar'>
							<SelectCountyRaw
								name='county'
								onChange={e => setCounty(e.currentTarget.value)}
								value={county}
							/>
							<Btn className='create-report-btn' disabled={!county} onClick={() => openModal()}>
								<Icon type='receipt' />
								Create Report
							</Btn>
						</div>
					</Card>
				</CardsSection>
			</Page>
			<Modal show={showModal} closeHandler={closeModal}>
				{canRender ? (
					<PrintWindow
						PDF={<PropertyTaxesPDF county={county} />}
						cb={closeModal}
						fileName={`${year}-${year + 1}-${county}-property-taxes`}
					/>
				) : (
					<Spinner />
				)}
			</Modal>
		</PropertyTaxesReportPageView>
	)
}
