import { authExchange } from '@urql/exchange-auth'
import { Auth } from 'aws-amplify'
import { makeOperation } from 'urql'

export const urqlAuthExchange = authExchange({
	getAuth: async ({ authState }) => {
		if (!authState) {
			const session = await Auth.currentSession()
			const token = session.getIdToken().getJwtToken()
			if (token) {
				return { token }
			} else return null
		}

		return null
	},

	addAuthToOperation: ({ authState, operation }) => {
		const state = authState as any
		if (!state?.token) return operation
		const fetchOptions =
			typeof operation.context.fetchOptions === 'function'
				? operation.context.fetchOptions()
				: operation.context.fetchOptions || {}

		return makeOperation(operation.kind, operation, {
			...operation.context,
			fetchOptions: {
				...fetchOptions,
				headers: {
					...fetchOptions.headers,
					Authorization: `Bearer ${state.token}`,
				},
			},
		})
	},

	didAuthError: ({ error }) => {
		console.log('error: ', error)
		return false
	},
})
