import React from 'react'
import styled from 'styled-components'
import { RelatedResourcesCard } from '../../../Components/common/RelatedResource.card'
import { Spinner, Tab, Tabs } from '../../../Components/UI'
import { RelatedEasementDocs } from './Easement.related.docs'
import { RelatedEasementLogs } from './Easement.related.logs'
import { RelatedEasementOrgs } from './Easement.related.orgs'
import { RelatedEasementParcels } from './Easement.related.parcels'

const Section = styled.section``

type EasementRelatedResourcesProps = {
	easementId: number
}
export const EasementRelatedResources: React.FC<EasementRelatedResourcesProps> = ({
	easementId,
}) => {
	return (
		<Section>
			<RelatedResourcesCard>
				{easementId ? (
					<Tabs renderOnSelect>
						<Tab nav='Parcels' icon='parcel'>
							<RelatedEasementParcels easementId={easementId} />
						</Tab>

						<Tab nav='Orgs' icon='org'>
							<RelatedEasementOrgs easementId={easementId} />
						</Tab>

						<Tab nav='Docs' icon='doc'>
							<RelatedEasementDocs easementId={easementId} />
						</Tab>
						<Tab nav='Activity' icon='log'>
							<RelatedEasementLogs easementId={easementId} />
						</Tab>
					</Tabs>
				) : (
					<Spinner />
				)}
			</RelatedResourcesCard>
		</Section>
	)
}
