import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'
import { ReportsExhibitPage, ReportsPage } from '../Pages/Reports'
import { PropertyTaxesReportPage } from '../Pages/Reports/Reports.propertyTaxes.page'

const ReportsRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<ReportsPage path='/' />
				<ReportsExhibitPage path='/exhibit' />
				<PropertyTaxesReportPage path='/property-taxes' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
export default ReportsRoutes
