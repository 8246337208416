import styled, { css } from 'styled-components'
import { usePaginatedLogsQuery } from '../../../../gql_generated/graphql'
import { GenLogModal } from '../../../common/Log/Log.gen.modal'
import { Card } from '../../../UI'
import { logWidgetColsConfig } from '../../../UI/Table.resources/Table.resource.col.configs/Log.col.configs'
import { ResourcesTable } from '../../../UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../UI/Table.resources/usePaginationParams'

export const activityLogDashWidgetStyles = css``

const ActivityLogDashWidgetView = styled.div`
	${activityLogDashWidgetStyles}
`

export type ActivityLogDashWidgetProps = {}
export const ActivityLogDashWidget = (): JSX.Element => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedLogsQuery({
		variables: {
			params: {
				...paginationStore.params,
			},
		},
	})
	const logs = fetchResults.data?.page.logs
	return (
		<ActivityLogDashWidgetView>
			<Card corners titleText='Recent Activity' headingSize='lg' icon='log'>
				<ResourcesTable
					dataset={logs}
					colsConfig={logWidgetColsConfig}
					fetchResults={fetchResults}
					paginationStore={paginationStore}
					modalRenderFunc={({ id }) => <GenLogModal logId={id} />}
					suppressGoTo
					displayOptions={{
						hideTotalQty: true,
						hidePagination: true,
						suppressSorting: true,
					}}
					limitSelectConfig={{
						hideLimitSelect: true,
					}}
				/>
			</Card>
		</ActivityLogDashWidgetView>
	)
}
