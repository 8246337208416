import {
	useLeaseForLeaseTypeQuery,
	useLeaseForLesseeQuery,
	useLeaseForTermDateToQuery,
	useLeaseForTotalAcresQuery,
} from '../../../../gql_generated/graphql'
import { asAcres, formatDate } from '../../../../utils'
import { sumBy } from '../../../../utils/lodash.utils'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

const LeaseAcres: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useLeaseForTotalAcresQuery({
		variables: { id },
	})
	const { activity } = fetchResults.data?.lease || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{asAcres(
				sumBy(activity, ({ acres, estate, assigneeId, isTerminated }) =>
					assigneeId || isTerminated ? 0 : acres || estate.acres
				)
			)}
		</ResourceTableCellAsync>
	)
}

/**
 *  Acres for parcel related leases
 * */
const genAcresFromParcelComponent =
	(parentId: number): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useLeaseForTotalAcresQuery({ variables: { id } })
		const { activity } = results.data?.lease || {}

		return (
			<ResourceTableCellAsync fetchResults={results}>
				{asAcres(
					sumBy(activity, ({ acres, estate, assigneeId, isTerminated, parcelId }) =>
						assigneeId || isTerminated || parcelId !== parentId ? 0 : acres || estate.acres
					)
				)}
			</ResourceTableCellAsync>
		)
	}

const LeaseLessee: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useLeaseForLesseeQuery({
		variables: { id },
	})
	const { lessee, og } = fetchResults.data?.lease || {}

	const ogLessee = og?.lessee && `*${og.lessee}`

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{lessee?.name || ogLessee || ''}
		</ResourceTableCellAsync>
	)
}

const LeaseType: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useLeaseForLeaseTypeQuery({
		variables: { id },
	})
	const { leaseType, og } = fetchResults.data?.lease || {}

	const ogLeaseType = og?.leaseType && `*${og.leaseType}`

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{leaseType?.name || ogLeaseType}
		</ResourceTableCellAsync>
	)
}

const LeaseTermDateTo: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useLeaseForTermDateToQuery({
		variables: { id },
	})
	const { currentTerm } = fetchResults.data?.lease || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{formatDate(currentTerm?.dateTo)}
		</ResourceTableCellAsync>
	)
}

export const leaseDateEffectiveColConfig: ColConfig = {
	field: 'dateEffective',
	format: 'date',
	label: 'Effective',
	sortField: 'dateEffective',
}

export const leaseLeaseIdColConfig: ColConfig = {
	field: 'leaseId',
	label: 'ID',
	sortField: 'leaseId',
}

export const leaseLesseeColConfig: ColConfig = {
	label: 'Lessee',
	sortField: 'lessee.name',
	Component: LeaseLessee,
}

export const leaseEstateTypeColConfig: ColConfig = {
	field: 'estateType',
	label: 'Estate Type',
	format: 'icon',
	hideMobile: true,
}

export const leaseLeaseTypeColConfig: ColConfig = {
	label: 'Type',
	hideMobile: true,
	Component: LeaseType,
}

export const leaseAcresColConfig: ColConfig = {
	label: 'Acres',
	format: 'acres',
	hideMobile: true,
	Component: LeaseAcres,
}

export const leaseAcresForParcelColConfig = (parcelId: number): ColConfig => ({
	label: 'Acres',
	hideMobile: true,
	Component: genAcresFromParcelComponent(parcelId),
})

export const leaseStatusColConfig: ColConfig = {
	label: 'Status',
	field: 'status',
	format: 'status',
	hideMobile: true,
}

export const leaseTermDateToColConfig: ColConfig = {
	label: 'Current Expiration',
	Component: LeaseTermDateTo,
	sortField: 'terms.dateTo',
}

/**
 *  Lease Col Config Collections
 * */

export const leasesPageColsConfig: ColConfig[] = [
	leaseDateEffectiveColConfig,
	leaseLeaseIdColConfig,
	leaseLesseeColConfig,
	leaseLeaseTypeColConfig,
	leaseAcresColConfig,
	leaseStatusColConfig,
	leaseTermDateToColConfig,
]

export const leaseColsForParcelLeases = (parcelId: number): ColConfig[] => [
	leaseDateEffectiveColConfig,
	leaseLeaseIdColConfig,
	leaseLesseeColConfig,
	leaseLeaseTypeColConfig,
	leaseAcresForParcelColConfig(parcelId),
	leaseStatusColConfig,
]

export const docRelatedLeasesColsConfig: ColConfig[] = [
	leaseDateEffectiveColConfig,
	leaseLeaseIdColConfig,
	leaseLesseeColConfig,
	leaseLeaseTypeColConfig,
	leaseAcresColConfig,
	leaseStatusColConfig,
]

export const orgRelatedLeasesColsConfig: ColConfig[] = [
	leaseDateEffectiveColConfig,
	leaseLeaseIdColConfig,
	leaseLesseeColConfig,
	leaseLeaseTypeColConfig,
	leaseAcresColConfig,
	leaseStatusColConfig,
]

export const researchRelatedLeasesColsConfig: ColConfig[] = [
	leaseDateEffectiveColConfig,
	leaseLeaseIdColConfig,
	leaseLesseeColConfig,
	leaseLeaseTypeColConfig,
	leaseAcresColConfig,
	leaseStatusColConfig,
]

export const expiredLeaseWidgetColsConfig: ColConfig[] = [
	leaseLeaseIdColConfig,
	leaseLesseeColConfig,
	leaseTermDateToColConfig,
]
