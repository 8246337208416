import { RouteComponentProps, useNavigate } from '@reach/router'
import React from 'react'
import CardsSection from '../../Components/common/CardsSection'
import { GenLeaseModal } from '../../Components/common/Lease/Lease.gen.modal'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import CreateLease from '../../Components/Ops/Ops.Leases/Ops.Lease.create'
import {
	Card,
	DateField,
	Modal,
	SelectCounty,
	SelectLeaseType,
	Text,
	ToggleField,
	useModalCtrl,
} from '../../Components/UI'
import { CsvDownload } from '../../Components/UI/CSV.download'
import { leasesPageColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Lease.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import {
	LeaseFilter,
	LeaseFilterOption,
	LeasePaginationInputs,
} from '../../gql_generated/document_types'
import { usePaginatedLeasesCsvMutation, usePaginatedLeasesQuery } from '../../gql_generated/graphql'
import { useAdvancedSearch, usePageData, useState } from '../../utils'

const LeasesPage: React.FC<RouteComponentProps> = () => {
	usePageData({ pageType: 'leases', pageTitle: 'Leases' })

	const [
		advancedSearch,
		{ showInactive, lesseeStr, leaseIdStr, leaseTypeId, termExpiration, taxReimburseable, county },
	] = useAdvancedSearch<{
		showInactive: boolean
		lesseeStr: string
		leaseIdStr: string
		leaseTypeId: number
		termExpiration: string
		taxReimburseable: boolean
		county: string
	}>({
		fields: [
			{
				label: 'By Lease ID',
				name: 'leaseIdStr',
				Component: Text,
			},
			{
				label: 'By Lessee',
				name: 'lesseeStr',
				Component: Text,
			},
			{
				name: 'leaseTypeId',
				label: 'By Lease Type',
				Component: SelectLeaseType,
			},
			// {
			// 	name: 'Expired Terms',
			// 	input: (
			// 		<Select
			// 			name='termExpiration'
			// 			label='Terms Expire Before'
			// 			options={[
			// 				{ val: '' },
			// 				{ val: 'NOW', txt: 'Now' },
			// 				{ val: 'NEXT MONTH' },
			// 				{ val: 'NEXT QAURTER' },
			// 				{ val: 'NEXT YEAR' },
			// 			]}
			// 		/>
			// 	),
			// },
			{
				name: 'termExpiration',
				input: <DateField name='termExpiration' label='Terms Expire By' />,
			},
			{
				name: 'showInactive',
				defaultVal: false,
				input: <ToggleField name='showInactive' label='Show Inactive' isSimple />,
			},
			{
				name: 'taxReimburseable',
				defaultVal: false,
				input: <ToggleField name='taxReimburseable' label='Tax Reimbursable' isSimple />,
			},
			{
				name: 'county',
				label: 'By County',
				Component: SelectCounty,
			},
		],
		executeOnChange: true,
	})

	const [Op, setOp] = useState<string | null>(null, 'Op')
	const navTo = useNavigate()

	const paginationStore = usePaginationParams()

	const filterOptions = [
		{
			filter: LeaseFilter.LeaseId,
			vals: [leaseIdStr],
		},
		{
			filter: LeaseFilter.LeaseTypeName,
			vals: [leaseTypeId],
		},
		{
			filter: LeaseFilter.Lessee,
			vals: [lesseeStr],
		},
		{
			filter: LeaseFilter.Status,
			vals: showInactive ? ['Terminated', 'Cancelled'] : ['Active'],
		},
		{
			filter: LeaseFilter.TaxReimbursable,
			vals: [taxReimburseable],
		},
		{
			filter: LeaseFilter.County,
			vals: [county],
		},
	] as LeaseFilterOption[]

	if (termExpiration)
		filterOptions.push({
			filter: LeaseFilter.TermExpiration,
			vals: [termExpiration],
		})

	const params = {
		...paginationStore.params,
		filterOptions,
	} as LeasePaginationInputs

	const [fetchResults] = usePaginatedLeasesQuery({
		variables: {
			params,
		},
	})
	const { data } = fetchResults

	const operations: PageOperation[] = [
		{
			txt: 'New Lease',
			icon: 'lease',
			op: () => setOp('create'),
		},
	]

	const leases = data?.page?.leases

	const [[showCsv, openCsv, closeCsv]] = useModalCtrl()
	const [csvMutResults, csvMut] = usePaginatedLeasesCsvMutation()

	return (
		<Page isLoaded operations={operations}>
			<CardsSection>
				<Card>
					<ResourcesTable
						dataset={leases}
						colsConfig={leasesPageColsConfig}
						paginationStore={paginationStore}
						fetchResults={fetchResults}
						modalRenderFunc={lease => <GenLeaseModal leaseId={lease.id} />}
						includeSearch
						resourceType='lease'
						advancedSearch={advancedSearch}
						miscOps={[
							{
								icon: 'download',
								title: 'CSV',
								op: openCsv,
							},
						]}
					/>
				</Card>
			</CardsSection>
			<Modal show={!!Op} closeHandler={() => setOp(null)} titleText='Create new lease'>
				{Op === 'create' ? (
					<CreateLease
						cb={leaseId => {
							if (leaseId) navTo(`/mgr/leases/${leaseId}`)
							else {
								setOp(null)
							}
						}}
					/>
				) : null}
			</Modal>
			<Modal show={showCsv} closeHandler={closeCsv}>
				<CsvDownload
					fetchData={() =>
						csvMut({
							params,
						})
					}
					fetchState={csvMutResults}
					csvData={csvMutResults.data?.csv}
					onAfterDownload={closeCsv}
					options={{
						filename: 'leases',
					}}
				/>
			</Modal>
		</Page>
	)
}
export default LeasesPage
