import styled, { css } from 'styled-components'
import NavMgr from '../../Nav/Nav.mgr'
import { Heading } from '../../UI/Heading'
import { ExpiredLeaseDashWidget } from '../DashWidgets/ExpiredLease.dash.widget'

export const officerDashboardStyles = css`
	padding: 1rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	width: 100%;
	max-width: 100vw;

	${props => props.theme.media.tablet} {
		grid-template-columns: 1fr 1fr;
	}

	.widget {
		width: 100%;
		max-width: 100%;
		min-width: 0;
	}

	.desk-only {
		display: none;
		${props => props.theme.media.sdesk} {
			display: block;
		}
	}
	.mobile-only {
		${props => props.theme.media.sdesk} {
			display: none;
		}
	}
`

const OfficerDashboardView = styled.div`
	${officerDashboardStyles}
`

export type OfficerDashboardProps = {}
export const OfficerDashboard = (): JSX.Element => {
	return (
		<OfficerDashboardView>
			<div className='widget desk-only'>
				<ExpiredLeaseDashWidget />
			</div>
			<div className='mobile-only'>
				<Heading mBot color='white'>
					Where would you like to go?
				</Heading>
				<NavMgr displayType='in-dash' />
			</div>
		</OfficerDashboardView>
	)
}
