import { sumBy } from 'lodash'
import { EstateStatus, ParcelParent } from '../../../../gql_generated/document_types'
import {
	useAgreementForEstateTypeQuery,
	useLeaseForEstateTypeQuery,
	useParcelForActivityAcresQuery,
	useParcelForActivityEstatesQuery,
	useParcelForAliquotQuery,
	useParcelForOwnedEstatesColQuery,
	useParcelForRelatedDocEstatesQuery,
	useParcelForSaleAcqusitionAcresQuery,
	useParcelForSrfAcresColQuery,
	useRoyaltyForParcelAcresColQuery,
	useRoyaltyForParcelAliquotColQuery,
} from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { asAcres } from '../../../../utils'
import EstateTypes from '../../../common/Estate/Estates.Icons'
import { Icon } from '../../Icon'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { Trunc } from '../../Trunc'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

/*
 =================================================
	SPECIAL COL COMPONENTS
	(These require nested resources that are not guaranteed to be available)
 =================================================
* */

/**
 *  Acres for parcels - (not related to any other resource)
 * */
const ParcelSrfAcres: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useParcelForSrfAcresColQuery({
		variables: { id },
	})
	const parcel = fetchResults.data?.parcel

	const srf = parcel?.srf

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{srf && srf.status === EstateStatus.Available ? asAcres(srf.acres) : 0}
		</ResourceTableCellAsync>
	)
}

/**
 *  Estates owned - (not related to any other resource)
 * */
const ParcelEstatesOwned: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useParcelForOwnedEstatesColQuery({
		variables: { id },
	})
	const { estates } = fetchResults.data?.parcel || {}
	const estateTypes = estates
		?.filter(({ status }) => status === 'Available')
		.map(({ type }) => type)
	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{estateTypes?.length ? (
				<EstateTypes selected={estateTypes} hideUnselected isStatic />
			) : (
				'None'
			)}
		</ResourceTableCellAsync>
	)
}

/**
 *  Acres for Sale and Acquisition related parcels
 * */
const genSaleAcquisitionAcresComponent =
	(parentId: number): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useParcelForSaleAcqusitionAcresQuery({ variables: { id } })
		const parcel = results.data?.parcel

		const { acres, activity } = parcel?.srf || {}
		const totalAcres =
			sumBy(activity, ea =>
				ea.saleId === parentId || ea.acquisitionId === parentId ? ea.acres || 0 : 0
			) ||
			acres ||
			0

		return (
			<ResourceTableCellAsync fetchResults={results}>{asAcres(totalAcres)}</ResourceTableCellAsync>
		)
	}

/**
 *  Acres for Lease, Agreement and Easement related Parcels
 * */

type LeaseAgreementEasement = 'lease' | 'agreement' | 'easement'
type LeaseAgreement = 'lease' | 'agreement'

const genLeaseAgreementEasementAcresComponent =
	(
		parentId: number,
		parentType: LeaseAgreementEasement,
		estateType: EstateTypeEnum
	): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useParcelForActivityAcresQuery({ variables: { id } })
		const parcel = results.data?.parcel

		const { estates, activity } = parcel || {}

		const totalAcres = sumBy(estates, ({ acres, status, type }) =>
			sumBy(activity, ea => {
				const eaId = ea.leaseId || ea.agreementId || ea.easementId

				const isEasement = parentType === 'easement'

				const canSum =
					type === estateType &&
					ea.estateType === type &&
					eaId === parentId &&
					(isEasement ? true : !ea.isTerminated && !ea.assigneeId && status !== 'Conveyed')

				return canSum ? ea.acres || acres : 0
			})
		)

		return (
			<ResourceTableCellAsync fetchResults={results}>{asAcres(totalAcres)}</ResourceTableCellAsync>
		)
	}

/**
 *  Aliquot for Lease, Agreement related parcels
 * */

const genLeaseAgreementAliquotComponent =
	(parentId: number, estateType: EstateTypeEnum): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useParcelForAliquotQuery({ variables: { id } })
		const parcel = results.data?.parcel

		const { estates, activity } = parcel || {}

		const { aliquot } = estates?.find(({ type }) => type === estateType) || {}

		const ea = activity?.find(ea => {
			const eaId = ea.leaseId || ea.agreementId || ea.easementId
			return ea.estateType === estateType && eaId === parentId
		})

		return (
			<ResourceTableCellAsync fetchResults={results}>
				{ea ? <Trunc>{ea.aliquot || aliquot}</Trunc> : null}
			</ResourceTableCellAsync>
		)
	}

/**
 *  Estates for Sale and Acquisition related parcels
 * */
const genSaleAcquisitionEstatesComponent =
	(parentId: number): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useParcelForActivityEstatesQuery({ variables: { id } })

		const { estates, activity } = results.data?.parcel || {}

		return (
			<ResourceTableCellAsync fetchResults={results}>
				<EstateTypes
					hideUnselected
					isStatic
					selected={estates
						?.filter(
							({ type }) =>
								!!activity?.find(
									ea =>
										ea.estateType === type &&
										(ea.saleId === parentId || ea.acquisitionId === parentId)
								)
						)
						.map(({ type }) => type)}
				/>
			</ResourceTableCellAsync>
		)
	}

/**
 *  Estates for Doc related parcels
 * */
const genDocEstatesComponent =
	(parentId: number): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useParcelForRelatedDocEstatesQuery({ variables: { id } })

		const docs = results.data?.parcel?.docs

		return (
			<ResourceTableCellAsync fetchResults={results}>
				<EstateTypes
					hideUnselected
					isStatic
					selected={docs
						?.filter(({ docId }) => docId === parentId)
						.map(({ estate }) => estate.type)}
				/>
			</ResourceTableCellAsync>
		)
	}

/**
 *  Estate icon for Lease related parcels
 * */

const genLeaseEstateIconComponent =
	(parentId: number): TableBodyCellComponent =>
	() => {
		const [results] = useLeaseForEstateTypeQuery({ variables: { id: parentId } })

		const estateType = results.data?.lease?.estateType

		return (
			<ResourceTableCellAsync fetchResults={results}>
				<Icon type={estateType as EstateTypeEnum} />
			</ResourceTableCellAsync>
		)
	}

/**
 *  Estate icon for Agreement related parcels
 * */
const genAgreementEstateIconComponent =
	(parentId: number): TableBodyCellComponent =>
	() => {
		const [results] = useAgreementForEstateTypeQuery({ variables: { id: parentId } })

		const estateType = results.data?.agreement?.estateType

		return (
			<ResourceTableCellAsync fetchResults={results}>
				<Icon type={estateType as EstateTypeEnum} />
			</ResourceTableCellAsync>
		)
	}

/**
 *  Aliquot for related parcels (for Sales, Leases, Agreements, Easements, Acquisitions)
 *  Not for Royalties or Docs.
 * */

const genRelatedParcelAliquot =
	({ parentId, parentType, estateType }: ParcelSelectorColsConfigParams): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useParcelForAliquotQuery({ variables: { id } })

		const { estates, activity } = results.data?.parcel || {}

		const estate = estates?.find(({ type }) => type === estateType || 'srf')

		let aliquot = estate?.aliquot || ''

		if (parentId && parentType) {
			const ea = activity?.find(
				ea => ea.estateType === estateType && ea[`${parentType}Id` as keyof typeof ea] === parentId
			)
			aliquot = ea?.aliquot || aliquot
		}

		return (
			<ResourceTableCellAsync fetchResults={results}>
				<Trunc maxChars={30}>{aliquot}</Trunc>
			</ResourceTableCellAsync>
		)
	}

/**
 *  Acres for royalty related parcels
 * */
const genRoyaltyAcresComponent =
	(royaltyId: number): TableBodyCellComponent =>
	({ id }) => {
		const [fetchResults] = useRoyaltyForParcelAcresColQuery({
			variables: { id: royaltyId },
		})
		const { royaltyActivity } = fetchResults.data?.royalty || {}

		const { acres, estateActivity } =
			royaltyActivity?.find(({ estateActivity }) => estateActivity.parcelId === id) || {}

		const royaltyAcres = acres || estateActivity?.acres || estateActivity?.estate.acres || 0

		const isInactive = estateActivity?.isTerminated || estateActivity?.assigneeId

		return (
			<ResourceTableCellAsync fetchResults={fetchResults}>
				{asAcres(isInactive ? 0 : royaltyAcres)}
			</ResourceTableCellAsync>
		)
	}

/**
 *  Aliquot for royalty related parcels
 * */
const genRoyaltyAliquotComponent =
	(royaltyId: number): TableBodyCellComponent =>
	({ id }) => {
		const [fetchResults] = useRoyaltyForParcelAliquotColQuery({
			variables: { id: royaltyId },
		})
		const { royaltyActivity } = fetchResults.data?.royalty || {}

		const { aliquot, estateActivity } =
			royaltyActivity?.find(({ estateActivity }) => estateActivity.parcelId === id) || {}

		const royaltyAliquot = aliquot || estateActivity?.aliquot || estateActivity?.estate?.aliquot

		const isInactive = estateActivity?.isTerminated || estateActivity?.assigneeId
		return (
			<ResourceTableCellAsync fetchResults={fetchResults}>
				{isInactive ? 'None' : <Trunc maxChars={30}>{royaltyAliquot}</Trunc>}
			</ResourceTableCellAsync>
		)
	}

/**
 *  Status for royalty related parcels
 * */
const genRoyaltyStatusComponent =
	(royaltyId: number): TableBodyCellComponent =>
	({ id }) => {
		const [fetchResults] = useRoyaltyForParcelAcresColQuery({
			variables: { id: royaltyId },
		})
		const { royaltyActivity } = fetchResults.data?.royalty || {}

		const { estateActivity } =
			royaltyActivity?.find(({ estateActivity }) => estateActivity.parcelId === id) || {}

		const status = estateActivity?.isTerminated
			? 'Terminated'
			: estateActivity?.assigneeId
			? 'Assigned'
			: 'Active'

		return <ResourceTableCellAsync fetchResults={fetchResults}>{status}</ResourceTableCellAsync>
	}

/**
 *  Description for easement
 * */
const genEasementDescriptionComponent =
	(easementId: number): TableBodyCellComponent =>
	({ id }) => {
		const [fetchResults] = useParcelForAliquotQuery({
			variables: { id },
		})
		const { activity } = fetchResults.data?.parcel || {}

		const ea = activity?.find(e => e.estateType === 'srf' && e.easementId === easementId)

		const description = ea?.aliquot || 'None entered'

		return (
			<ResourceTableCellAsync fetchResults={fetchResults}>
				{description || null}
			</ResourceTableCellAsync>
		)
	}

/*
 =================================================
	COL CONFIGS (INDIVIDUAL COLS)
 =================================================
* */

export const parcelsStateColConfig: ColConfig = {
	field: 'state',
	format: 'upper',
	hideMobile: true,
	hideTablet: true,
}

export const parcelsCountyColConfig: ColConfig = {
	field: 'county',
	format: 'proper',
	sortField: 'county',
}

export const parcelsApnColConfig: ColConfig = {
	label: 'APN',
	field: 'apn',
	sortField: 'apn',
}

export const parcelsTrsColConfig: ColConfig = {
	label: 'Township',
	field: 'trs',
	sortField: 'section,range,town',
}

export const parcelsOwnedSrfAcresColConfig: ColConfig = {
	label: 'Surface',
	hideMobile: true,
	Component: ParcelSrfAcres,
}

export const saleAcquisitionRelatedParcelsAcresColConfig = (parentId: number): ColConfig => ({
	label: 'Srf Conveyed',
	hideMobile: true,
	Component: genSaleAcquisitionAcresComponent(parentId),
})

export const saleAcquisitionRelatedParcelsEstatesColConfig = (parentId: number): ColConfig => ({
	label: 'Estates Conveyed',
	hideMobile: true,
	hideTablet: true,
	Component: genSaleAcquisitionEstatesComponent(parentId),
})

export const docParcelsEstatesColConfig = (parentId: number): ColConfig => ({
	label: 'Estates',
	hideMobile: true,
	hideTablet: true,
	Component: genDocEstatesComponent(parentId),
})

export const leaseAgreementEasementRelatedParcelsAcresColConfig = (
	parentId: number,
	parentType: LeaseAgreementEasement,
	estateType: EstateTypeEnum
): ColConfig => ({
	label: parentType === 'easement' ? 'Eased' : parentType === 'lease' ? 'Leased' : 'Agreed',
	hideMobile: true,
	Component: genLeaseAgreementEasementAcresComponent(parentId, parentType, estateType),
})

export const leaseAgreementRelatedParcelsAliquotColConfig = (
	parentId: number,
	parentType: LeaseAgreement,
	estateType: EstateTypeEnum
): ColConfig => ({
	label: parentType === 'lease' ? 'Leased Aliquot' : 'Agreed Aliquot',
	hideMobile: true,
	hideTablet: true,
	Component: genLeaseAgreementAliquotComponent(parentId, estateType),
})

export const leaseAgreementEstateIconColConfig = (
	parentId: number,
	parentType: LeaseAgreement
) => ({
	label: 'Estate',
	hideMobile: true,
	Component:
		parentType === 'lease'
			? genLeaseEstateIconComponent(parentId)
			: genAgreementEstateIconComponent(parentId),
})

export const parcelsOwnedEstateIconsColConfig: ColConfig = {
	label: 'Estates Owned',
	hideMobile: true,
	hideTablet: true,
	Component: ParcelEstatesOwned,
}

export const parcelAliquotColConfig = (params: ParcelSelectorColsConfigParams): ColConfig => ({
	label: 'Aliquot',
	hideMobile: true,
	hideTablet: true,
	Component: genRelatedParcelAliquot(params),
})

export const royaltyParcelAcresColConfig = (royaltyId: number): ColConfig => ({
	label: 'Acres',
	hideMobile: true,
	Component: genRoyaltyAcresComponent(royaltyId),
})

export const royaltyParcelAliquotColConfig = (royaltyId: number): ColConfig => ({
	label: 'Aliquot',
	hideMobile: true,
	hideTablet: true,
	Component: genRoyaltyAliquotComponent(royaltyId),
})

export const royaltyParcelStatusColConfig = (royaltyId: number): ColConfig => ({
	label: 'Status',
	hideMobile: true,
	hideTablet: true,
	Component: genRoyaltyStatusComponent(royaltyId),
})

export const easementDescriptionColConfig = (easementId: number): ColConfig => ({
	label: 'Description',
	hideMobile: true,
	hideTablet: true,
	Component: genEasementDescriptionComponent(easementId),
})

/*
 =================================================
	COLS CONFIG COLLECTIONS
 =================================================
* */

export const parcelsPageColsConfig: ColConfig[] = [
	parcelsStateColConfig,
	parcelsCountyColConfig,
	parcelsApnColConfig,
	parcelsTrsColConfig,
	parcelsOwnedSrfAcresColConfig,
	parcelsOwnedEstateIconsColConfig,
]

export const relatedParcelsColsConfig: ColConfig[] = [
	parcelsStateColConfig,
	parcelsCountyColConfig,
	parcelsApnColConfig,
	parcelsTrsColConfig,
]

export const saleAcqusitionRelatedParcelsColsConfig: (
	parentId: number
) => ColConfig[] = parentId => [
	parcelsStateColConfig,
	parcelsCountyColConfig,
	parcelsApnColConfig,
	parcelsTrsColConfig,
	saleAcquisitionRelatedParcelsAcresColConfig(parentId),
	saleAcquisitionRelatedParcelsEstatesColConfig(parentId),
]

export const docRelatedParcelsColsConfig: (parentId: number) => ColConfig[] = parentId => [
	parcelsStateColConfig,
	parcelsCountyColConfig,
	parcelsApnColConfig,
	parcelsTrsColConfig,
	docParcelsEstatesColConfig(parentId),
]

export const leaseAgreementRelatedParcelColsConfig: (
	parentId: number,
	parentType: LeaseAgreement,
	estateType: EstateTypeEnum
) => ColConfig[] = (parentId, parentType, estateType) => [
	parcelsStateColConfig,
	parcelsCountyColConfig,
	parcelsApnColConfig,
	parcelsTrsColConfig,
	leaseAgreementEasementRelatedParcelsAcresColConfig(parentId, parentType, estateType),
	leaseAgreementRelatedParcelsAliquotColConfig(parentId, parentType, estateType),
]

export const easementRelatedParcelsColsConfig: (parentId: number) => ColConfig[] = parentId => [
	parcelsStateColConfig,
	parcelsCountyColConfig,
	parcelsApnColConfig,
	parcelsTrsColConfig,
	// leaseAgreementEasementRelatedParcelsAcresColConfig(parentId, 'easement', 'srf'),
	easementDescriptionColConfig(parentId),
]

type ParcelSelectorColsConfigParams = {
	parentType?: ParcelParent
	parentId?: number
	estateType?: EstateTypeEnum
}
export const parcelSelectorColsConfig: (
	params: ParcelSelectorColsConfigParams
) => ColConfig[] = params => [
	parcelsCountyColConfig,
	parcelsApnColConfig,
	parcelsTrsColConfig,
	parcelAliquotColConfig(params),
]

export const royaltyRelatedParcelColsConfig: (parentId: number) => ColConfig[] = parentId => [
	parcelsCountyColConfig,
	parcelsApnColConfig,
	parcelsTrsColConfig,
	royaltyParcelAcresColConfig(parentId),
	royaltyParcelAliquotColConfig(parentId),
	royaltyParcelStatusColConfig(parentId),
]
