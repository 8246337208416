/*
 =================================================
  Default vals for forms
  These are indicitive of empty values for all properties on each resource.
 =================================================
* */
import camelCase from 'lodash/camelCase'
import uniq from 'lodash/uniq'
import { SelectOptions } from '../Components/UI/Form.Fields/Select'
import { SaleStatus } from '../gql_generated/document_types'
import { estateTypes, formatDate } from './utils'

/**
 *  PARCEL
 * */
export const defaultParcelVals = {
	country: 'USA',
	meridian: 'M.D.M',
	state: 'NV',
	county: 'Pershing',
	apn: '',
	town: '',
	range: '',
	section: '',
	notes: '',
	aliquot: '',
	aliquotNotes: '',
	acres: 0, // NOTE: this doesn't persist to the server

	...estateTypes.reduce((obj: any, { type, typeFull }) => {
		obj[type] = {
			acres: 0,
			type,
			typeFull,
			aliquot: '',
		}
		return obj
	}, {}),
}
// END PARCEL

/**
 *  SALE
 * */
export const defaultSaleVals = {
	escrowId: '',
	notes: '',
	dateExpected: '',
	dateOpened: formatDate(new Date(), 'input') || '',
	dateInsurExpires: '',
	cbInterest: 0,
	cbNote: '',
	cbYears: 0,
	deposit: '',
	isDepositTowardPurchase: true,
	price: '',
}
// END SALE

/**
 *  Acquisition
 * */
export const defaultAcquisitionVals = {
	escrowId: '',
	notes: '',
	grantorId: '',
	dateExpected: '',
	dateOpened: formatDate(new Date(), 'input') || '',
	dateInsurExpires: '',
	cbInterest: 0,
	cbNote: '',
	cbYears: 0,
	deposit: '',
	isDepositTowardPurchase: true,
	price: '',
}
// END Acquisition

/**
 *  LEASE
 * */

export const defaultLeaseTermVals = {
	hasContClause: false,
	hasPurchaseOption: false,
	hasTaxReimbursements: false,
	rate: 0,
	schedAmount: 1,
	schedInterval: 'years',
	dateFrom: formatDate(new Date(), 'input') || '',
	dateTo: '',
	termNotes: '',
}

export const defaultLeaseFeeVals = {}

export const defaultLeaseVals = {
	leaseId: '',
	canRenew: false,
	estateType: 'srf',
	leaseTypeId: '',
	lesseeId: '',
	initialFee: '',
	dateEffective: formatDate(new Date(), 'input') || '',
	dateShouldExpire: '',
	notes: '',
	terms: {
		...defaultLeaseTermVals,
	},
}

// END LEASE

/**
 *  EASEMENT
 * */
export const defaultEasementVals = {
	price: '',
	easementType: '',
	dateEffective: '',
	escrowId: '',
	notes: '',
}
// END EASEMENT

/**
 *  AGREEMENT
 * */
export const defaultAgreementVals = {
	agreementId: '',
	agreementType: '',
	estateType: '',
	dateEffective: '',
	notes: '',
}
// END AGREEMENT

/**
 *  ORG
 * */
const address = {
	city: '',
	state: 'NV',
	country: 'USA',
	street: '',
	street2: '',
	zip: '',
}
export const defaultOrgVals = {
	name: '',
	email: '',
	phone: '',
	notes: '',
	dbas: [
		{
			name: '',
		},
	],
	address,
}

export const defaultContactVals = {
	firstName: '',
	lastName: '',
	email: '',
	phoneMobile: '',
	phoneWork: '',
	phoneExt: '',
	title: '',
	notes: '',
	address,
}
// END ORG

/**
 *  DOC
 * */
export const defaultDocVals = {
	isRecDoc: true,
	docType: '',
	dateEffective: formatDate(new Date(), 'input'),
	docName: '',
	notes: '',

	recDocId: '',
	dateRecorded: formatDate(new Date(), 'input'),
	book: '',
	page: '',
	state: 'NV',
	county: '',

	mediaId: '',
}
// END DOC

export const parcelStatuses = ['Surface Conveyed', 'Surface Unowned', 'Available']

export const royaltyStatuses = ['Active', 'Terminated']

export const saleStatuses = Object.values(SaleStatus)

export const acquisitionStatuses = Object.values(SaleStatus)

export const leaseStatuses = ['Active', 'Terminated', 'Expired', 'Past Expiration']

export const agreementStatuses = ['Active', 'Terminated', 'Expired', 'Past Expiration']

export const easementStatuses = ['Active', 'Terminated']

export type StatusObj = {
	statusName: string
	icon: string
}

export const resourceStatuses: StatusObj[] = uniq([
	...parcelStatuses,
	...royaltyStatuses,
	...saleStatuses,
	...acquisitionStatuses,
	...leaseStatuses,
	...agreementStatuses,
	...easementStatuses,
]).map(status => ({
	statusName: status,
	icon: camelCase(status),
}))

const nextYear = new Date().getFullYear() + 1
export const propertyTaxYearOptions: SelectOptions[] = new Array(10)
	.fill('')
	.map((_, idx) => ({
		val: nextYear - idx,
	}))
	.reverse()
