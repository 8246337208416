import { CacheExchangeOpts } from '@urql/exchange-graphcache'
import { urqlPagination } from './Urq.pagination'
import {
	invalidateOnEstateActivityMuts,
	invalidateOnRoyaltyMuts,
	invalidateQueriesByFieldNames,
	invalidateQueryByFieldName,
} from './Urql.invalidationResolvers'

/**
 *  URQL NORMALIZED CACHE CONFIGURATION
 * */
export const urqlCacheConfig: CacheExchangeOpts = {
	resolvers: {
		// NOTE: These are only for debugging options and does not change the result of the query.
		PaginatedParcels: {
			parcels: urqlPagination({ debug: false }),
		},
		PaginatedSales: {
			sales: urqlPagination({ debug: false }),
		},

		Query: {
			parcel: (_, args) => ({ __typename: 'Parcel', id: args.id }),
			sale: (_, args) => ({ __typename: 'Sale', id: args.id }),
			lease: (_, args) => ({ __typename: 'Lease', id: args.id }),
			easement: (_, args) => ({ __typename: 'Easement', id: args.id }),
			agreement: (_, args) => ({ __typename: 'Agreement', id: args.id }),
			acquisition: (_, args) => ({ __typename: 'Acquisition', id: args.id }),
			org: (_, args) => ({ __typename: 'Org', id: args.id }),
			contact: (_, args) => ({ __typename: 'Contact', id: args.id }),
			doc: (_, args) => ({ __typename: 'Doc', id: args.id }),
			royalty: (_, args) => ({ __typename: 'Royalty', id: args.id }),
			estateActivity: (_, args) => ({ __typename: 'EstateActivity', id: args.id }),
			log: (_, args) => ({ __typename: 'Log', id: args.id }),
		},
	},

	updates: {
		Mutation: {
			/**
			 *  Acquisitions
			 * */
			createAcquisition: invalidateQueryByFieldName({
				fieldName: 'paginatedAcquisitions',
			}),
			deleteAcquisition: invalidateQueryByFieldName({
				fieldName: 'paginatedAcquisitions',
			}),
			updateAcquisitionStatus: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedAcquisitions', 'acquisition'],
			}),

			/**
			 *  Agreements
			 * */
			createAgreement: invalidateQueryByFieldName({
				fieldName: 'paginatedAgreements',
			}),
			deleteAgreement: invalidateQueryByFieldName({
				fieldName: 'paginatedAgreements',
			}),
			updateAgreementStatus: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedAgreements', 'agreement'],
			}),

			/**
			 *  Docs
			 * */
			createDoc: invalidateQueryByFieldName({
				fieldName: 'paginatedDocs',
			}),
			updateDoc: invalidateQueryByFieldName({
				fieldName: 'paginatedDocs',
			}),
			addDocRelToDoc: invalidateQueryByFieldName({
				fieldName: 'paginatedDocs',
			}),
			removeDocFromResource: invalidateQueryByFieldName({
				fieldName: 'paginatedDocs',
			}),
			removeDocsFromResource: invalidateQueryByFieldName({
				fieldName: 'paginatedDocs',
			}),
			removeResourceFromDoc: invalidateQueryByFieldName({
				fieldName: 'paginatedDocs',
			}),
			uploadFile: invalidateQueriesByFieldNames({
				fieldNames: ['doc', 'file'],
			}),
			deleteFile: invalidateQueryByFieldName({
				fieldName: 'doc',
			}),

			/**
			 *  Easements
			 * */
			createEasement: invalidateQueryByFieldName({
				fieldName: 'paginatedEasements',
			}),
			deleteEasement: invalidateQueryByFieldName({
				fieldName: 'paginatedEasements',
			}),

			/**
			 *  Estate Activity
			 * */
			estateActivity: invalidateOnEstateActivityMuts({ debug: false }),
			deleteEstateActivity: invalidateOnEstateActivityMuts({ debug: false }),
			addActivityEstateTypes: invalidateOnEstateActivityMuts({ debug: false }),
			removeActivityEstateTypes: invalidateOnEstateActivityMuts({ debug: false }),
			terminateEstateActivity: invalidateOnEstateActivityMuts({ debug: false }),
			unTerminateEstateActivity: invalidateOnEstateActivityMuts({ debug: false }),
			assignEstateActivity: invalidateOnEstateActivityMuts({ debug: false }),
			unAssignEstateActivity: invalidateOnEstateActivityMuts({ debug: false }),

			/**
			 *  Leases
			 * */
			createLease: invalidateQueryByFieldName({
				fieldName: 'paginatedLeases',
			}),
			updateLease: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedLeases', 'lease'],
			}),
			updateLeaseStatus: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedLeases', 'lease'],
			}),
			deleteLease: invalidateQueryByFieldName({
				fieldName: 'paginatedLeases',
			}),
			updateLeaseTerm: invalidateQueriesByFieldNames({
				fieldNames: ['lease', 'paginatedLeaseTerms', 'paginatedLeases'],
			}),
			createLeaseTerm: invalidateQueriesByFieldNames({
				fieldNames: ['lease', 'paginatedLeaseTerms'],
			}),
			createLeaseFee: invalidateQueriesByFieldNames({
				fieldNames: ['lease'],
			}),
			updateLeaseFinancialNotes: invalidateQueriesByFieldNames({
				fieldNames: ['lease'],
			}),

			/**
			 *  Orgs
			 * */
			createOrg: invalidateQueryByFieldName({
				fieldName: 'paginatedOrgs',
			}),
			updateOrg: invalidateQueryByFieldName({
				fieldName: 'paginatedOrgs',
			}),
			createDba: invalidateQueriesByFieldNames({ fieldNames: ['paginatedOrgs', 'org'] }),
			deleteDba: invalidateQueriesByFieldNames({ fieldNames: ['paginatedOrgs', 'org'] }),
			togglePrimaryContact: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedOrgs', 'paginatedContacts'],
			}),
			createContact: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedOrgs', 'paginatedContacts'],
			}),
			updateContact: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedOrgs', 'paginatedContacts'],
			}),
			deleteContact: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedOrgs', 'paginatedContacts'],
			}),

			/**
			 *  Parcels
			 * */
			addParcelsToDoc: invalidateQueryByFieldName({
				fieldName: 'paginatedParcels',
				debug: false,
			}),
			removeParcelsFromDoc: invalidateQueryByFieldName({
				fieldName: 'paginatedParcels',
				debug: false,
			}),
			createPropertyTax: invalidateQueriesByFieldNames({
				fieldNames: ['paginatedParcels', 'parcel'],
			}),
			batchCreatePropertyTax: invalidateQueriesByFieldNames({
				fieldNames: ['parcelsForBatchTaxCreate'],
			}),

			/**
			 *  Royalties
			 * */
			createRoyalty: invalidateOnRoyaltyMuts({ debug: false }),
			deleteRoyalties: invalidateOnRoyaltyMuts(),
			updateRoyalty: invalidateOnRoyaltyMuts({ debug: false }),

			/**
			 *  Sales
			 * */
			createSale: invalidateQueryByFieldName({
				fieldName: 'paginatedParcels',
			}),
			deleteSale: invalidateQueryByFieldName({
				fieldName: 'paginatedParcels',
			}),
			updateSaleStatus: invalidateQueryByFieldName({
				fieldName: 'paginatedParcels',
			}),
		},
	},

	keys: {
		/**
		 *  Unkeyable fields
		 * */
		ParcelOG: () => null,
		SaleOG: () => null,
		LeaseOG: () => null,
		AgreementOG: () => null,
		DocOg: () => null,
		ParcelTotals: () => null,
		Totals: () => null,
		Address: () => null,
		LogDataChangeLog: () => null,
	},
}
