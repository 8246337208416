import {
	useSaleForEstateIconsColQuery,
	useSaleForGranteeColQuery,
	useSaleForSrfAcresColQuery,
} from '../../../../gql_generated/graphql'
import { asAcres } from '../../../../utils'
import { sumBy } from '../../../../utils/lodash.utils'
import EstateTypes from '../../../common/Estate/Estates.Icons'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

const SaleSrfAcres: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useSaleForSrfAcresColQuery({
		variables: { id },
	})
	const { activity } = fetchResults.data?.sale || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{asAcres(
				sumBy(activity, ({ acres, estate, estateType }) =>
					estateType === 'srf' ? acres || estate.acres : 0
				)
			)}
		</ResourceTableCellAsync>
	)
}

/**
 *  Acres for parcel related leases
 * */
const genAcresFromParcelComponent =
	(parentId: number): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useSaleForSrfAcresColQuery({ variables: { id } })
		const { activity } = results.data?.sale || {}

		return (
			<ResourceTableCellAsync fetchResults={results}>
				{asAcres(
					sumBy(activity, ({ acres, estate, parcelId, estateType }) =>
						estateType !== 'srf' || parcelId !== parentId ? 0 : acres || estate.acres
					)
				)}
			</ResourceTableCellAsync>
		)
	}

const SaleGrantee: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useSaleForGranteeColQuery({
		variables: { id },
	})
	const { grantee, og } = fetchResults.data?.sale || {}
	// console.log('grantee sale: ', fetchResults.data?.sale)
	const ogGrantee = og?.grantee && `*${og.grantee}`
	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{grantee?.name || ogGrantee || 'Unknown'}
		</ResourceTableCellAsync>
	)
}

const genSaleEstateTypes =
	(parcelId?: number): TableBodyCellComponent =>
	({ id }) => {
		const [fetchResults] = useSaleForEstateIconsColQuery({
			variables: { id },
		})
		const { activity } = fetchResults.data?.sale || {}

		return (
			<ResourceTableCellAsync fetchResults={fetchResults}>
				{activity?.length ? (
					<EstateTypes
						isStatic
						selected={activity
							.filter(ea => (parcelId ? ea.parcelId === parcelId : true))
							.map(({ estateType }) => estateType)}
						hideUnselected
					/>
				) : null}
			</ResourceTableCellAsync>
		)
	}

export const salesEscrowIdColConfig: ColConfig = {
	field: 'escrowId',
	label: 'Escrow ID',
	sortField: 'escrowId',
	hideMobile: true,
}

export const salesGranteeColConfig: ColConfig = {
	label: 'Grantee',
	sortField: 'grantee.name',
	Component: SaleGrantee,
}

export const salesConveyedSrfAcresColConfig: ColConfig = {
	label: 'Surface',
	hideMobile: true,
	Component: SaleSrfAcres,
}

export const saleAcresForParcelColConfig = (parcelId: number): ColConfig => ({
	label: 'Acres',
	hideMobile: true,
	Component: genAcresFromParcelComponent(parcelId),
})

export const salesPriceColConfig: ColConfig = {
	field: 'price',
	format: 'currency',
	label: 'Price',
	hideMobile: true,
}

export const salesStatusColConfig: ColConfig = {
	field: 'status',
	hideMobile: true,
	format: 'status',
}

export const salesEstateIconsColConfig: ColConfig = {
	label: 'Estates',
	hideMobile: true,
	Component: genSaleEstateTypes(),
}

export const salesEstatesForParcelColConfig: (parcelId: number) => ColConfig = parcelId => ({
	label: 'Estates',
	hideMobile: true,
	Component: genSaleEstateTypes(parcelId),
})

export const salesDateClosedColConfig: ColConfig = {
	field: 'dateClosed',
	format: 'date',
	label: 'Closed',
	sortField: 'dateClosed',
}

export const salesDateOpenedColConfig: ColConfig = {
	field: 'dateOpened',
	format: 'date',
	label: 'Opened',
	sortField: 'dateOpened',
}

export const salesDateCancelledColConfig: ColConfig = {
	field: 'dateCancelled',
	format: 'date',
	label: 'Cancelled',
	sortField: 'dateCancelled',
}

export const salesDateForeclosedColConfig: ColConfig = {
	field: 'dateForeclosed',
	format: 'date',
	label: 'Foreclosed',
	sortField: 'dateForeclosed',
}

export const salesDateExpectedColConfig: ColConfig = {
	field: 'dateExpected',
	format: 'date',
	label: 'Expected To Close',
	sortField: 'dateExpected',
}

export const salesPageOpenColsConfig: ColConfig[] = [
	salesDateOpenedColConfig,
	salesDateExpectedColConfig,
	salesEscrowIdColConfig,
	salesGranteeColConfig,
	salesPriceColConfig,
	salesConveyedSrfAcresColConfig,
	salesEstateIconsColConfig,
]

export const salesPageForeclosedColsConfig: ColConfig[] = [
	salesDateClosedColConfig,
	salesDateForeclosedColConfig,
	salesEscrowIdColConfig,
	salesGranteeColConfig,
	salesPriceColConfig,
	salesConveyedSrfAcresColConfig,
	salesEstateIconsColConfig,
]

export const salesPageClosedColsConfig: ColConfig[] = [
	salesDateClosedColConfig,
	salesEscrowIdColConfig,
	salesGranteeColConfig,
	salesPriceColConfig,
	salesConveyedSrfAcresColConfig,
	salesEstateIconsColConfig,
]

export const salesPageCancelledColsConfig: ColConfig[] = [
	salesDateCancelledColConfig,
	salesEscrowIdColConfig,
	salesGranteeColConfig,
	salesPriceColConfig,
	salesConveyedSrfAcresColConfig,
	salesEstateIconsColConfig,
]

export const saleColsForParcelSales = (parcelId: number): ColConfig[] => [
	salesDateClosedColConfig,
	salesEscrowIdColConfig,
	salesGranteeColConfig,
	salesEstatesForParcelColConfig(parcelId),
	salesStatusColConfig,
]
