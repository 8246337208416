import isPlainObject from 'lodash/isPlainObject'
import { ExhibitPdfConfig } from '.'
import { EstateActivity, Parcel } from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { formatNumberWithCommas, isDev } from '../../../utils'
import { round, sortBy } from '../../../utils/lodash.utils'
import { EM, TablePrintCell, TablePrintRow } from '../../UI'

export const genExhibitTwnFromParcel = ({ town, range, meridian }: Parcel) =>
	`Township ${town}, Range ${range}, ${meridian}`

const genCountyTitlePrintRow = (
	data: {
		county: string
		state: string
	},
	config: ExhibitPdfConfig
): TablePrintRow => {
	const { county, state } = data

	const { showApns } = config

	return {
		cells: [],
		sectionTitle: (
			<EM>
				All that property situated in {county}, {state.toUpperCase()}, more particularly described
				as:
			</EM>
		),
		colSpan: showApns ? 5 : 4,
	}
}

const genTwnTitlePrintRow = (
	data: {
		town: string
		range: string
		meridian: string
	},
	config: ExhibitPdfConfig
): TablePrintRow => {
	const { town, range, meridian } = data
	const { showApns } = config

	return {
		rangeTitle: `Township ${town}, Range ${range}, ${meridian}`,
		rangeTitleColSpan: 3,
		cells: showApns
			? [
					{
						val: 'APN',
					},
					{
						val: 'Acres',
					},
			  ]
			: [
					{
						val: 'Acres',
					},
			  ],
	}
}

const genSecPrintRow = (
	data: {
		section: string
		aliquot: string
		acres: number
		apn?: string
	},
	config: ExhibitPdfConfig
): TablePrintRow => {
	const { showApns } = config

	const { section, aliquot, acres, apn } = data

	const cells: Array<TablePrintCell | string> = [
		'Section',
		section,
		{ val: aliquot, isMultiLine: true },
	]

	if (showApns) cells.push(apn || '')

	cells.push(`${formatNumberWithCommas(acres)}`)

	return {
		cells: cells.map(val =>
			isPlainObject(val) ? (val as unknown as TablePrintCell) : { val }
		) as TablePrintCell[],
	}
}

const genSecPrintRowFromParcel = (
	parcel: Parcel,
	estateType: EstateTypeEnum,
	config: ExhibitPdfConfig
): TablePrintRow => {
	const { section, apn, estates } = parcel
	const estate = parcel[estateType] || estates.find(({ type }) => type === estateType)
	const aliquot = estate?.aliquot || ''
	const acres = estate?.acres || 0

	return genSecPrintRow(
		{
			section,
			aliquot,
			acres,
			apn,
		},
		config
	)
}

const genSecPrintRowFromActivity = (
	activity: EstateActivity,
	estateType: EstateTypeEnum,
	config: ExhibitPdfConfig
): TablePrintRow => {
	const { parcel } = activity

	const { section, apn } = parcel
	const estate = activity.estate || parcel[estateType as EstateTypeEnum]
	const aliquot = activity.aliquot || estate?.aliquot || ''
	const acres = activity.acres || estate?.acres

	return genSecPrintRow(
		{
			section,
			aliquot,
			acres,
			apn,
		},
		config
	)
}

const genCountyTotalPrintRow = (
	data: {
		county: string
		acres: number
	},
	config: ExhibitPdfConfig
) => {
	const { showApns } = config
	const { county, acres } = data

	return {
		cells: [],
		colSpan: showApns ? 5 : 4,
		sectionFooter: (
			<span className='section-footer'>
				<EM>Total acres for {county} county:</EM>
				<span className='acres'>{formatNumberWithCommas(round(acres, 2))}</span>
			</span>
		),
	}
}

type TownObj = {
	town: string
	range: string
	meridian: string
	parcels?: Parcel[]
	activity?: EstateActivity[]
}

const genTownObjsFromParcels = (parcels: Parcel[]) =>
	sortBy(parcels, ({ town, range }) => `${town}${range}`).reduce((array, parcel) => {
		const arry = [...array] as TownObj[]
		const { town, range, meridian } = parcel

		const townIdx = arry.findIndex(
			t => t.town === town && t.range === range && t.meridian === meridian
		)

		if (townIdx >= 0) {
			arry[townIdx].parcels?.push(parcel)
		} else {
			arry.push({
				town,
				range,
				meridian,
				parcels: [parcel],
			})
		}

		return arry
	}, [] as TownObj[])

const genTownObjsFromActivity = (activity: EstateActivity[]) =>
	sortBy(activity, ({ parcel }) => `${parcel?.town}${parcel?.range}`).reduce((array, ea) => {
		const arry = [...array] as TownObj[]
		const { parcel } = ea
		const { town, range, meridian } = parcel

		const townIdx = arry.findIndex(
			t => t.town === town && t.range === range && t.meridian === meridian
		)

		if (townIdx >= 0) {
			arry[townIdx].activity?.push(ea)
		} else {
			arry.push({
				town,
				range,
				meridian,
				activity: [ea],
			})
		}

		return arry
	}, [] as TownObj[])

const genTownPrintRowsFromObj = (
	townObj: TownObj,
	estateType: EstateTypeEnum,
	config: ExhibitPdfConfig
): TablePrintRow[] => {
	const { town, range, meridian, parcels, activity } = townObj

	const secRows = parcels
		? sortBy(parcels, 'section')?.map(parcel =>
				genSecPrintRowFromParcel(parcel, estateType, config)
		  )
		: sortBy(activity, ({ parcel }) => parcel?.section)?.map(ea =>
				genSecPrintRowFromActivity(ea, estateType, config)
		  ) || []

	return [genTwnTitlePrintRow({ town, range, meridian }, config), ...secRows]
}

type CountyObj = {
	state: string
	county: string
	parcels?: Parcel[]
	activity?: EstateActivity[]
	acres: number
}

const genCountyPrintRowFromObj = (
	countyObj: CountyObj,
	estateType: EstateTypeEnum,
	config: ExhibitPdfConfig
): TablePrintRow[] => {
	const { parcels, activity, county, state, acres } = countyObj

	const townObjs = parcels?.length
		? genTownObjsFromParcels(parcels)
		: activity?.length
		? genTownObjsFromActivity(activity)
		: []

	const townRows = townObjs.reduce(
		(arry, townObj) => [...arry, ...genTownPrintRowsFromObj(townObj, estateType, config)],
		[] as TablePrintRow[]
	)

	return [
		genCountyTitlePrintRow({ state, county }, config),
		...townRows,
		genCountyTotalPrintRow({ county, acres }, config),
	]
}

export type ExhibitPdfPrintRowsAndAcres = {
	printRows: TablePrintRow[]
	acres: number
}

export const genExhibitPdfPrintRowsFromParcels = (
	parcels: Parcel[],
	estateType: EstateTypeEnum,
	config: ExhibitPdfConfig
): ExhibitPdfPrintRowsAndAcres => {
	isDev() && console.log('Generating print rows from parcels')
	const counties = sortBy(parcels, 'county').reduce((array, parcel) => {
		const { county, state } = parcel
		const arry = [...array] as CountyObj[]

		const estate = parcel.estates?.find(({ type }) => type === estateType)
		const acres = estate?.acres || 0

		const countyIdx = arry.findIndex(c => c.county === county)
		if (countyIdx >= 0) {
			arry[countyIdx].parcels?.push(parcel)
			arry[countyIdx].acres += acres
		} else {
			arry.push({
				state,
				county,
				parcels: [parcel],
				acres,
			})
		}

		return arry
	}, [] as CountyObj[])

	let acres = 0
	const printRows = counties.reduce((arry, c) => {
		acres += c.acres
		return [...arry, ...genCountyPrintRowFromObj(c, estateType, config)]
	}, [] as TablePrintRow[])

	return {
		printRows,
		acres,
	}
}

export const genExhibitPdfPrintRowsFromActivity = (
	activity: EstateActivity[],
	estateType: EstateTypeEnum,
	config: ExhibitPdfConfig
): ExhibitPdfPrintRowsAndAcres => {
	isDev() && console.log('Generating print rows from activity')
	const { includeAssigned, includeTerminated } = config

	const counties = sortBy(activity, ({ parcel }) => parcel.county).reduce((array, ea) => {
		const { parcel, assigneeId, isTerminated } = ea

		if (
			(!includeAssigned && assigneeId) ||
			(!includeTerminated && isTerminated) ||
			ea.estateType !== estateType
		)
			return array

		const { county, state } = parcel
		const arry = [...array] as CountyObj[]

		const estate = ea.estate || parcel.estates?.find(({ type }) => type === estateType)
		const acres = ea.acres || estate.acres

		const countyIdx = arry.findIndex(c => c.county === county)
		if (countyIdx >= 0) {
			arry[countyIdx].acres += acres
			arry[countyIdx].parcels?.push(parcel)
			arry[countyIdx].activity?.push(ea)
		} else {
			arry.push({
				state,
				county,
				activity: [ea],
				acres,
			})
		}

		return arry
	}, [] as CountyObj[])

	let acres = 0
	const printRows = counties.reduce((arry, c) => {
		acres += c.acres
		return [...arry, ...genCountyPrintRowFromObj(c, estateType, config)]
	}, [] as TablePrintRow[])

	return {
		printRows,
		acres,
	}
}
