import { Redirect, RouteComponentProps } from '@reach/router'
import React from 'react'
import CardsSection from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import { GenParcelModal } from '../../Components/common/Parcel/Parcel.gen.modal'
import Page from '../../Components/common/Resources/Resource.page'
import { CreateParcel } from '../../Components/Ops/Ops.Parcels'
import {
	Card,
	Modal,
	SelectCounty,
	SelectEstateTypes,
	Text,
	useModalCtrl,
} from '../../Components/UI'
import { CsvDownload } from '../../Components/UI/CSV.download'
import { parcelsPageColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { ParcelFilter, ParcelPaginationInputs, UserRole } from '../../gql_generated/document_types'
import {
	usePaginatedParcelsCsvMutation,
	usePaginatedParcelsQuery,
} from '../../gql_generated/graphql'
import { EstateTypeEnum } from '../../Types'
import { nnrMgrs, useAdvancedSearch, usePageData, useState } from '../../utils'

const ParcelsPage: React.FC<RouteComponentProps> = () => {
	usePageData({ pageType: 'parcels', pageTitle: 'Parcels' })

	const [Op, setOp] = useState<string | null>(null, 'Op')
	const [redirect, setRedirect] = useState<string | null>(null, 'redirect')

	const operations: PageOperation[] = [
		{
			txt: 'New Parcel',
			icon: 'parcel',
			op: () => setOp('create'),
			allowed: [...nnrMgrs],
		},
		{
			txt: 'Property Taxes',
			icon: 'receipt',
			op: () => setRedirect('batch-tax-create'),
			allowed: [...nnrMgrs, UserRole.Accountant],
		},
	]

	const [advancedSearch, { apnStr, townStr, rangeStr, countyStr, ownedEstates }] =
		useAdvancedSearch<{
			apnStr: string
			townStr: string
			rangeStr: string
			countyStr: string
			ownedEstates: EstateTypeEnum[]
		}>({
			fields: [
				{
					label: 'By APN',
					name: 'apnStr',
					Component: Text,
				},
				{
					label: 'By Town',
					name: 'townStr',
					Component: Text,
				},
				{
					label: 'By Range',
					name: 'rangeStr',
					Component: Text,
				},
				{
					label: 'By County',
					name: 'countyStr',
					Component: SelectCounty,
				},
				{
					label: 'By Owned Estates',
					name: 'ownedEstates',
					Component: SelectEstateTypes,
					// Component: () => (
					// 	<Select
					// 		name='ownedEstate'
					// 		label='By Owned Estate'
					// 		options={[
					// 			{ txt: 'Select...', val: '' },
					// 			...estateTypes.map(({ type, typeFull }) => ({
					// 				val: type,
					// 				txt: startCase(typeFull),
					// 			})),
					// 		]}
					// 	/>
					// ),
				},
			],
			executeOnChange: true,
		})

	/**
	 *  Data fetching...
	 * */

	const paginationStore = usePaginationParams()

	const params = {
		...paginationStore.params,
		filterOptions: [
			{
				filter: ParcelFilter.County,
				vals: [countyStr],
			},
			{
				filter: ParcelFilter.Apn,
				vals: [apnStr],
			},
			{
				filter: ParcelFilter.Town,
				vals: [townStr],
			},
			{
				filter: ParcelFilter.Range,
				vals: [rangeStr],
			},
			{
				filter: ParcelFilter.EstatesOwned,
				vals: ownedEstates,
			},
		],
	} as ParcelPaginationInputs

	const [fetchResults] = usePaginatedParcelsQuery({
		variables: {
			params,
		},
	})
	const { data } = fetchResults

	const parcels = data?.page?.parcels

	const [[showCsv, openCsv, closeCsv]] = useModalCtrl()
	const [csvMutResults, csvMut] = usePaginatedParcelsCsvMutation()

	return redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<Page isLoaded operations={operations}>
			<CardsSection>
				<Card>
					<ResourcesTable
						dataset={parcels}
						colsConfig={parcelsPageColsConfig}
						fetchResults={fetchResults}
						paginationStore={paginationStore}
						modalRenderFunc={val => <GenParcelModal parcelId={val.id} />}
						resourceType='parcel'
						includeSearch
						advancedSearch={advancedSearch}
						limitSelectConfig={{}}
						miscOps={[
							{
								icon: 'download',
								title: 'CSV',
								op: openCsv,
							},
						]}
					/>
				</Card>
			</CardsSection>
			<Modal show={!!Op} closeHandler={() => setOp(null)} titleText='Create new parcel'>
				{Op === 'create' ? (
					<CreateParcel
						cb={parcel => {
							const id = parcel?.data?.createParcel.id
							if (id) setRedirect(`/mgr/parcels/${id}`)
							else {
								setOp(null)
							}
						}}
					/>
				) : null}
			</Modal>
			<Modal show={showCsv} closeHandler={closeCsv}>
				<CsvDownload
					fetchData={() =>
						csvMut({
							params,
						})
					}
					fetchState={csvMutResults}
					csvData={csvMutResults.data?.csv}
					onAfterDownload={closeCsv}
					options={{
						filename: 'parcels',
					}}
				/>
			</Modal>
		</Page>
	)
}
export default ParcelsPage
