import React from 'react'

export type TablePrintCell = {
	val: string | number
	isMultiLine?: boolean
}

type TableCellPrintProps = {
	cell: TablePrintCell
}
const TableCellPrint: React.FC<TableCellPrintProps> = ({ cell }) => {
	const { val, isMultiLine } = cell
	return <td className={isMultiLine ? `__multi-line` : ''}>{`${val}`}</td>
}
export default TableCellPrint
