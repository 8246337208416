import { Parcel, PropertyTax } from '../../../gql_generated/document_types'
import { formatNumberWithCommas } from '../../../utils'
import { round } from '../../../utils/lodash.utils'
import { TablePrintRow } from '../../UI'

type ParcelType = {
	apn: Parcel['apn']
	currentPropertyTax?: Pick<PropertyTax, 'amount' | 'year'> | null
}
export type PropertyTaxesReportPrintRows = { rows: TablePrintRow[]; total: number }
export const generatePropertyTaxReportPrintRows = ({
	parcels,
}: {
	parcels: ParcelType[]
}): PropertyTaxesReportPrintRows => {
	let total = 0

	const rows = parcels.map(({ apn, currentPropertyTax }) => {
		total += currentPropertyTax?.amount ?? 0

		return {
			cells: [
				{
					val: apn,
				},
				{
					val: currentPropertyTax?.amount
						? formatNumberWithCommas(round(currentPropertyTax.amount, 2))
						: '?',
				},
			],
		}
	}) as TablePrintRow[]

	return {
		rows: [
			{
				rangeTitle: 'APN',
				cells: [{ val: '$ Amount' }],
			},
			...rows,
		],
		total,
	}
}
