import { RouteComponentProps } from '@reach/router'
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import CardsSection from '../../Components/common/CardsSection'
import Page from '../../Components/common/Resources/Resource.page'
import { Async, Btn, Card, SelectCountyRaw, SelectRaw, Spinner, toastit } from '../../Components/UI'
import { Table, TableProps } from '../../Components/UI/Table/Table'
import { Parcel, PropertyCreateTaxInput, PropertyTax } from '../../gql_generated/document_types'
import {
	useCreateBatchPropertyTaxMutation,
	useParcelsForBatchTaxCreateQuery,
} from '../../gql_generated/graphql'
import { propertyTaxYearOptions, usePageData, useState } from '../../utils'

export const propertyTaxBatchCreatePageStyles = css`
	.operations-header {
		display: flex;
		align-items: stretch;

		& > * {
			margin-right: 1em;

			&:last-child {
				margin-right: 0;
			}
		}

		.total-results {
			display: flex;
			align-items: center;
			.note {
				margin-left: 1em;
				font-style: italic;
				display: none;
				${props => props.theme.media.sdesk} {
					display: inline-block;
				}
			}
		}

		.save-btn {
			margin-left: auto;
			padding: 0 2em;
			border: solid 2px;
		}
	}

	.content-table {
		${props => props.theme.media.sdesk} {
			height: calc(80vh - ${p => p.theme.sizes.header.sdesk.val});
			overflow-y: auto;
		}
	}
	input {
		border: none;
		box-shadow: none;
		outline: none !important;
	}

	.field {
		display: flex;
		align-items: center;
	}

	.disabled-field {
		color: ${props => props.theme.colors.lightGrey.val};
	}

	.input-field {
		input {
			border: 1px solid ${props => props.theme.colors.grey.light().val};
			padding: 4px;

			&:focus {
				border-color: ${props => props.theme.colors.secondary.val};
			}
		}
	}

	table {
		position: relative;
		height: auto;

		thead {
			background: ${props => props.theme.colors.white.val};
		}
		thead,
		th {
			position: sticky;
			top: 0;
			z-index: 10;
		}
	}
`

const PropertyTaxBatchCreatePageView = styled.div`
	${propertyTaxBatchCreatePageStyles}
`
type PropertyTaxData = Pick<PropertyTax, 'id' | 'amount' | 'year'>
type TaxData = PropertyCreateTaxInput & {
	parcel: Pick<Parcel, 'id' | 'apn' | 'town' | 'range' | 'section'> & {
		propertyTaxes?: PropertyTaxData[] | null
		currentPropertyTax?: PropertyTaxData | null
	}
	thisTax?: PropertyTaxData
	lastTax?: PropertyTaxData
}

export const PropertyTaxBatchCreatePage = (_: RouteComponentProps): JSX.Element => {
	usePageData({ pageType: 'parcels', pageTitle: 'Property Taxes' })
	const [county, setCounty] = useState('', 'county')
	const [results] = useParcelsForBatchTaxCreateQuery({ variables: { county }, pause: !county })
	const parcels = results.data?.parcels

	const thisYear = new Date().getFullYear()
	const [selectedYear, setSelectedYear] = useState(thisYear + 1, 'selectedYear')

	const [taxData, setTaxData] = useState<TaxData[]>([], 'taxData')

	const inputRefs = useRef<HTMLInputElement[]>([])

	const [focusIdx, setFocusIdx] = useState(0, 'focuxIdx')

	useEffect(() => {
		if (parcels)
			setTaxData(
				parcels.map(parcel => {
					const thisTax = parcel.propertyTaxes?.find(tax => tax.year === selectedYear)
					const lastTax = parcel.propertyTaxes?.find(tax => tax.year === selectedYear - 1)

					return {
						id: thisTax?.id,
						parcelId: parcel.id,
						amount: thisTax?.amount ?? ('' as unknown as number),
						parcel,
						thisTax,
						lastTax,
					}
				})
			)
	}, [parcels, selectedYear, county])

	const [mutRes, mut] = useCreateBatchPropertyTaxMutation()
	const submitHandler = useCallback(
		async () => {
			try {
				const data = taxData
					.filter(({ amount }) => !!amount)
					.map(({ id, amount, parcelId }) => ({
						id,
						amount,
						parcelId,
						year: selectedYear,
					}))

				if (!data?.length) return

				const res = await mut({
					data,
				})

				if (res.error) throw res.error
				else {
					toastit.ok('Progress saved!')
					setTaxData(state =>
						state.map(item =>
							item.id
								? item
								: {
										...item,
										id: res.data?.taxes.find(tax => tax.parcelId === item.parcelId)?.id,
								  }
						)
					)
				}
			} catch (err) {
				console.error(err)
				toastit.err('Error saving progress')
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedYear, taxData]
	)

	const keyUpHandler = useCallback(
		(e: KeyboardEvent<HTMLInputElement>, idx) => {
			const key = e.key

			if (key === 'Enter') {
				setFocusIdx(state => {
					const i = typeof idx === 'number' ? idx : state
					if (i + 1 === (parcels?.length ?? 0)) return i
					else return i + 1
				})
			}
		},
		[parcels?.length]
	)

	const onChangeHandler = useCallback(
		(e: ChangeEvent<HTMLInputElement>, idx: number) => {
			const val = e.currentTarget.value

			if (!val) return

			setTaxData(state => {
				const newState = [...state]
				newState[idx] = {
					...state[idx],
					amount: parseFloat(val),
				}

				return newState
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedYear, taxData]
	)

	// const onBlurHandler = useCallback(() => {
	// 	submitHandler()
	// }, [submitHandler])

	useEffect(() => {
		inputRefs.current[focusIdx]?.focus()
	}, [focusIdx])

	const rows: TableProps<any>['rows'] = taxData.map(({ amount, lastTax, parcel }, rowIdx) => {
		const { id, apn, town, range, section } = parcel
		return {
			rowIdx,
			id: id,
			cells: [
				{ txt: apn },
				{ txt: `${town} ${range} ${section}` },

				{
					txt: (
						<div className='field input-field'>
							$
							<input
								type='number'
								onKeyUp={e => keyUpHandler(e, rowIdx)}
								value={amount}
								ref={el => (inputRefs.current[rowIdx] = el as HTMLInputElement)}
								onChange={e => onChangeHandler(e, rowIdx)}
							/>
						</div>
					),
				},
				{
					txt: (
						<div className='field disabled-field'>
							$
							<input value={lastTax?.amount ?? 0.0} disabled />
						</div>
					),
				},
			],
		}
	})

	return (
		<Page isLoaded>
			<PropertyTaxBatchCreatePageView>
				<CardsSection>
					<Card>
						<header className='operations-header'>
							<SelectRaw
								name='year'
								value={selectedYear}
								// disabled={!!county}
								options={propertyTaxYearOptions.map(({ val }) => ({
									val,
									txt: typeof val === 'number' ? `${val - 1} - ${val}` : val,
								}))}
								onChange={e => setSelectedYear(parseInt(e.currentTarget.value))}
							/>
							<SelectCountyRaw
								name='county'
								onChange={e => {
									setCounty(e.currentTarget.value)
								}}
							/>
							{parcels?.length ? (
								<div className='total-results'>
									{parcels.length} parcels found{' '}
									<span className='note'>
										NOTE: Includes only parcels for which the surface estate is owned
									</span>
								</div>
							) : null}
							<Btn
								className='save-btn'
								disabled={mutRes.fetching || !taxData?.length}
								onClick={submitHandler}
							>
								{mutRes.fetching ? <Spinner /> : null}Save
							</Btn>
						</header>
						<main>
							<Async fetchResults={results}>
								{taxData?.length ? (
									<Table
										className='content-table'
										cols={[
											{
												label: 'APN',
											},
											{ label: 'Town' },
											{ label: `${selectedYear - 1}-${selectedYear}` },
											{ label: `${selectedYear - 2}-${selectedYear - 1}` },
										]}
										rows={rows}
									/>
								) : null}
							</Async>
						</main>
					</Card>
				</CardsSection>
			</PropertyTaxBatchCreatePageView>
		</Page>
	)
}
