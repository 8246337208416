import React from 'react'
import styled from 'styled-components'
import TableBodyPrint from './Table.body.print'
import { TablePrintRow } from './Table.row.print'

const TablePrintView = styled.table`
	width: 100%;

	td {
		padding: 2px 0;
		padding-right: 12px;
		white-space: nowrap;

		&:last-child {
			padding-right: 0;
			text-align: right;
		}

		&.section-title-cell {
			border-top: 3px solid;
			padding-bottom: 15px;
			&:last-child {
				text-align: left;
			}
		}

		&.range-title-cell {
			font-weight: 700;
			border-bottom: 1px solid;
			padding-top: 12px;
		}

		&.section-footer-cell {
			padding-top: 6px;
			padding-bottom: 45px;
		}

		&.__multi-line {
			white-space: normal;
		}
	}
`

type TablePrintProps = {
	body: {
		rows: TablePrintRow[]
	}
}
export const TablePrint: React.FC<TablePrintProps> = ({ body }) => {
	const { rows } = body
	return (
		<TablePrintView>
			<TableBodyPrint>{rows}</TableBodyPrint>
		</TablePrintView>
	)
}
