import React from 'react'
import styled, { css } from 'styled-components'
import { formatNumberWithCommas } from '../../../utils'
import { round } from '../../../utils/lodash.utils'

export const exhibitPdfTotalsStyles = css``

const ExhibitPdfTotalsView = styled.div`
	${exhibitPdfTotalsStyles}
`

type ExhibitPdfTotalsProps = {
	// config: ExhibitPdfConfig
	totalAcres: number
}
const ExhibitPdfTotals: React.FC<ExhibitPdfTotalsProps> = ({ totalAcres }) => {
	return (
		<ExhibitPdfTotalsView className='totals'>
			<div className='label'>Total Acres:</div>
			<div className='val'>{formatNumberWithCommas(round(totalAcres, 2))}</div>
		</ExhibitPdfTotalsView>
	)
}
export default ExhibitPdfTotals
