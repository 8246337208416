import React from 'react'
import styled from 'styled-components'
import { RelatedResourcesCard } from '../../../Components/common/RelatedResource.card'
import { Spinner, Tab, Tabs } from '../../../Components/UI'
import { RelatedSaleDocs } from './Sale.related.docs'
import { RelatedSaleLogs } from './Sale.related.logs'
import { RelatedSaleOrgs } from './Sale.related.orgs'
import { RelatedSaleParcels } from './Sale.related.parcels'
import { RelatedSaleRoyalties } from './Sale.related.royalties'

const Section = styled.section``

type SaleRelatedResourcesProps = {
	saleId: number
}
const SaleRelatedResources: React.FC<SaleRelatedResourcesProps> = ({ saleId }) => {
	return (
		<Section>
			<RelatedResourcesCard>
				{saleId ? (
					<Tabs renderOnSelect>
						<Tab nav='Parcels' icon='parcel'>
							<RelatedSaleParcels saleId={saleId} />
						</Tab>

						<Tab nav='Royalties' icon='royalty'>
							<RelatedSaleRoyalties saleId={saleId} />
						</Tab>

						<Tab nav='Orgs' icon='org'>
							<RelatedSaleOrgs saleId={saleId} />
						</Tab>

						<Tab nav='Docs' icon='doc'>
							<RelatedSaleDocs saleId={saleId} />
						</Tab>
						<Tab nav='Activity' icon='log'>
							<RelatedSaleLogs saleId={saleId} />
						</Tab>
					</Tabs>
				) : (
					<Spinner />
				)}
			</RelatedResourcesCard>
		</Section>
	)
}
export default React.memo(SaleRelatedResources)
