import React from 'react'
import styled from 'styled-components'
import { RelatedResourcesCard } from '../../../Components/common/RelatedResource.card'
import { Spinner, Tab, Tabs } from '../../../Components/UI'
import { RelatedAgreementDocs } from './Agreement.related.docs'
import { RelatedAgreementLogs } from './Agreement.related.logs'
import { RelatedAgreementOrgs } from './Agreement.related.orgs'
import { RelatedAgreementParcels } from './Agreement.related.parcels'

const Section = styled.section``

type AgreementRelatedResourcesProps = {
	agreementId: number
}
const AgreementRelatedResources: React.FC<AgreementRelatedResourcesProps> = ({ agreementId }) => {
	return (
		<Section>
			<RelatedResourcesCard>
				{agreementId ? (
					<Tabs renderOnSelect>
						<Tab nav='Parcels' icon='parcel'>
							<RelatedAgreementParcels agreementId={agreementId} />
						</Tab>

						<Tab nav='Orgs' icon='org'>
							<RelatedAgreementOrgs agreementId={agreementId} />
						</Tab>

						<Tab nav='Docs' icon='doc'>
							<RelatedAgreementDocs agreementId={agreementId} />
						</Tab>
						<Tab nav='Activity' icon='log'>
							<RelatedAgreementLogs agreementId={agreementId} />
						</Tab>
					</Tabs>
				) : (
					<Spinner />
				)}
			</RelatedResourcesCard>
		</Section>
	)
}
export default AgreementRelatedResources
