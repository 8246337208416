import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedOrgColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Org.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { OrgParent } from '../../../gql_generated/document_types'
import { usePaginatedOrgsQuery } from '../../../gql_generated/graphql'
import { RelatedParcelOrgModal } from '../RelatedModals/Parcel.related.org.modal'

export const relatedParcelOrgsStyles = css``

const RelatedParcelOrgsView = styled.div`
	${relatedParcelOrgsStyles}
`

export type RelatedParcelOrgsProps = {
	parcelId: number
}

export const RelatedParcelOrgs: React.FC<RelatedParcelOrgsProps> = ({ parcelId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedOrgsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: OrgParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.orgs
	return (
		<RelatedParcelOrgsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedOrgColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={org => <RelatedParcelOrgModal orgId={org.id} />}
				resourceType='org'
			/>
		</RelatedParcelOrgsView>
	)
}
