import styled, { css } from 'styled-components'
import { LeaseFilter } from '../../../../gql_generated/document_types'
import { usePaginatedLeasesQuery } from '../../../../gql_generated/graphql'
import { GenLeaseModal } from '../../../common/Lease/Lease.gen.modal'
import { Card } from '../../../UI'
import { expiredLeaseWidgetColsConfig } from '../../../UI/Table.resources/Table.resource.col.configs/Lease.col.configs'
import { ResourcesTable } from '../../../UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../UI/Table.resources/usePaginationParams'

export const expiredLeaseDashWidgetStyles = css``

const ExpiredLeaseDashWidgetView = styled.div`
	${expiredLeaseDashWidgetStyles}
`

export type ExpiredLeaseDashWidgetProps = {}
export const ExpiredLeaseDashWidget = (): JSX.Element => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedLeasesQuery({
		variables: {
			params: {
				...paginationStore.params,
				filterOptions: [
					{
						filter: LeaseFilter.TermExpiration,
						vals: ['NOW'],
					},
				],
			},
		},
	})
	const data = fetchResults.data?.page.leases
	return (
		<ExpiredLeaseDashWidgetView>
			<Card corners titleText='Expired Leases' headingSize='lg' icon='lease'>
				<ResourcesTable
					dataset={data}
					colsConfig={expiredLeaseWidgetColsConfig}
					fetchResults={fetchResults}
					paginationStore={paginationStore}
					modalRenderFunc={({ id }) => <GenLeaseModal leaseId={id} />}
					resourceType='lease'
					displayOptions={{
						hideTotalQty: true,
						hidePagination: true,
						suppressSorting: true,
					}}
					limitSelectConfig={{
						hideLimitSelect: true,
					}}
				/>
			</Card>
		</ExpiredLeaseDashWidgetView>
	)
}
