import get from 'lodash/get'
import React, { HtmlHTMLAttributes } from 'react'
import styled from 'styled-components'
import { useStoreActions, useStoreState } from '../../store'
import Branding from '../common/Branding'
import { Btn, Icon } from '../UI'
import Nav from './Nav.mgr'

const NavWrap = styled.aside`
	transition: all ${props => props.theme.times.tranS};
	${props => props.theme.media.sdesk} {
		z-index: 10;
		background: ${props => props.theme.colors.slate.val};

		align-items: center;
		width: ${props => get(props.theme.sizes, 'deskNav.val')};
		&:before {
			display: block;
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			z-index: 2;
			width: 20px;
			background: linear-gradient(
				to left,
				${props => props.theme.colors.black.tint(20).val} 0%,
				${props => props.theme.colors.black.tint(0).val} 100%
			);
		}

		.nav-toggle-btn {
			position: absolute;
			right: -0.5em;
			top: 0.5em;
			transform: translateX(100%);
			color: ${props => props.theme.colors.grey.light(0).val};
		}
		.nav-wrap {
			padding-top: 8px;
			overflow: hidden;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			.branding {
				width: 60px;
				height: auto;
				display: block;
				margin-left: ${props => props.theme.sizes.gutter.mobile.val};
				margin-bottom: 8vh;
				transition: opacity ${props => props.theme.times.tranS};
				svg {
					height: auto;
					width: 100%;
				}
			}
			.resources {
				padding-left: ${props => props.theme.sizes.gutter.mobile.val};
			}

			li {
				&:first-child {
					.link {
						border-top: solid 1px ${props => props.theme.colors.grey.tint(20).val};
					}
				}
			}

			.link {
				border-bottom: solid 1px ${props => props.theme.colors.grey.tint(20).val};

				.icon,
				.txt {
					transition: all ${props => props.theme.times.tranS};
				}
			}
		}

		&.__collapsed {
			&:before {
				display: none;
			}

			.nav-wrap {
				.branding {
					opacity: 0;
					pointer-events: none;
				}
				nav {
					.link {
						padding-left: 3px;
						border: none;

						&:hover {
							background: none;
						}

						&:before,
						.txt {
							opacity: 0;
						}
						.icon {
							height: auto;
							max-width: 35px;
						}
					}
				}
			}
		}
	}
`

const DeskNav: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const { isDeskMenuOpen } = useStoreState(state => state.view)
	const { toggleDeskMenu } = useStoreActions(actions => actions.view)

	const navWidth = '200px'

	return (
		<NavWrap
			className={`desk-nav${className ? ` ${className}` : ''}${
				!isDeskMenuOpen ? ' __collapsed' : ''
			}`}
			style={{
				maxWidth: isDeskMenuOpen ? navWidth : '60px',
				minWidth: isDeskMenuOpen ? navWidth : '60px',
			}}
		>
			<Btn className='nav-toggle-btn' onClick={() => toggleDeskMenu(null)}>
				<Icon type='hamburger' />
			</Btn>
			<div className='nav-wrap'>
				<Branding />
				<Nav />
			</div>
		</NavWrap>
	)
}
export default DeskNav
